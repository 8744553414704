import { createElement, useEffect, useState } from "react";
import useNotifications from "src/pages/Notifications/useNotifications";
import { getInputValueByType } from "src/providers/inputProvider";
import { addCartouche, deleteCartouche, getDataStructure, getListCartouche, updateCartouche } from "src/services/api";
import { CartoucheType } from "src/types/cartouche";

const useCartouche = () => {
    const [cartouches, setCartouches] = useState<CartoucheType[]>();

    const [mandatoryParams, setMandatoryParams] = useState<any>();
    const [create, setCreate] = useState<any>();
    const [showErrors, setShowErrors] = useState<any>(true);

    const [dataStructureContainer, setDataStructureContainer] = useState<any>();
    const [dataTypes, setDataTypes] = useState<any>();
    const [formData, setFormData] = useState<any>();
    const [open, setOpen] = useState<boolean>(false);
    const [formDataEdit, setFormDataEdit] = useState<any>();
    const [boolInputs, setBoolInputs] = useState<any>();

    const [productEdit, setProductEdit] = useState<number | undefined>(undefined);

    const { addNotification } = useNotifications();

    useEffect(() => {
        initDatas();
    }, []);

    const initDatas = () => {
        getDataStructure().then((ds) => {
            let dss = ds.data;
            // We only want "CARTOUCHE" tab
            //dss.datastructure = dss.datastructure.filter((elem: any) => elem.primary);
            dss.datastructure = dss.datastructure.filter((elem: any) => elem.primary && elem.related_data == "CARTOUCHE");

            setDataStructureContainer(dss.datastructure[0].fields);
            setDataTypes({
                dataUnits: dss.dataUnits,
                dataMediaTypes: dss.dataMediaTypes,
                dataAvantages: dss.dataAvantages,
            });

            let tempMandatoryParams: any = new Set();

            dss.datastructure[0].fields.forEach((_: any, fieldId: number) => {
                if (_.mandatory && _.field_type !== "boolean") {
                    tempMandatoryParams.add(_.field_name);
                }
            });
            setMandatoryParams([...tempMandatoryParams]);

            let tempBoolInputs: Set<string> = new Set();

            dss.datastructure[0].fields.forEach((dsc: any) => {
                if (dsc.filed_type === "boolean") {
                    tempBoolInputs.add(dsc.field_name);
                }
            });
            setBoolInputs(tempBoolInputs);
        });

        getListCartouche().then((data: any) => {
            setCartouches(data.data.results);
        });
    };

    useEffect(() => {
        if (!dataStructureContainer) return;
        setEmptyFormData();
    }, [dataStructureContainer]);

    const checkMandatory = (data: any) => {
        let missingFields: any = new Set();

        mandatoryParams.forEach((mandatoryParamId: string) => {
            if (!data[mandatoryParamId] && data[mandatoryParamId] !== false) {
                missingFields.add(mandatoryParamId);
            }
        });

        if (missingFields.size) {
            addNotification("warning", "Certains champs ne sont pas remplis", `Les champs ${Array.from(missingFields).join(", ")} sont obligatoires`, 5000);
        }
        return missingFields.size;
    };

    const checkFormat = (data: any) => {
        let formatMismatched: { status: boolean; expectedType: string | string[] | undefined; fieldName: string | undefined }[] = [];
        Object.entries(data).forEach(([key, value]: [string, any]) => {
            let fieldParams = dataStructureContainer.find((field: any) => field.field_name === key);
            let formatTest = {
                status: false,
                expectedType: fieldParams?.format_control,
                fieldName: fieldParams?.field_name,
            };
            if (value !== "" && value) {
                switch (fieldParams?.format_control) {
                    case "float":
                        formatTest.status = /^[0-9.]+$/gm.test(value);
                        break;
                    case "integer":
                        formatTest.status = /^[0-9]+$/gm.test(value);
                        break;

                    case "email":
                        formatTest.status = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
                        break;

                    case "url":
                        formatTest.status = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/.test(value);
                        break;

                    case "DD/MM/YYYY":
                        formatTest.status = !!Date.parse(value);
                        break;

                    case "string":
                        formatTest.status = value === value.toString() || value.every((_: any) => typeof _ === "string");
                        break;

                    default:
                        formatTest.status = true;
                }

                if (!formatTest.status) {
                    formatMismatched.push(formatTest);
                }
            }
        });

        if (formatMismatched.length) {
            addNotification(
                "danger",
                "Mauvais format des inputs",
                createElement(
                    "p",
                    null,
                    formatMismatched.map((formatTestResult) => {
                        return createElement("div", null, `Le champ ${formatTestResult.fieldName} est du type ${formatTestResult.expectedType}`);
                    })
                ),
                5000
            );
        }

        return formatMismatched.length;
    };

    const setEmptyFormData = () => {
        let tempForm: any = {};
        dataStructureContainer.forEach((dataStructure: any) => {
            let value = getInputValueByType(dataStructure);
            tempForm[dataStructure.field_name] = value;
        });
        setFormData(tempForm);
    };

    useEffect(() => {
        if (productEdit || productEdit === 0) {
            setFormDataEdit(cartouches![productEdit]);
        }
    }, [productEdit]);

    const setInputState = async (index: any, name: string, value: any) => {
        let temp = { ...formData };
        temp[name] = value;
        setFormData(temp);
    };
    const setInputStateEdit = async (index: any, name: string, value: any) => {
        let temp = { ...formDataEdit };
        temp[name] = value;
        setFormDataEdit(temp);
    };

    const deleteCart = (ID: string) => {
        deleteCartouche(ID)
            .then((data: any) => {
                initDatas();
                addNotification("success", "Cartouche supprimée", "La cartouche a bien été supprimée !");
            })
            .catch(() => {
                addNotification("danger", "Une erreur est survenue", "La cartouche n'a pas pu être supprimée...");
            });
    };

    const submitNewCartouche = () => {
        setShowErrors(true);
        if (checkMandatory(formData)) {
            return;
        }
        if (checkFormat(formData)) {
            return;
        }
        addCartouche(formData)
            .then((data: any) => {
                initDatas();
                addNotification("success", "Cartouche créée", "La cartouche a bien été créée !");
            })
            .catch(() => {
                addNotification("danger", "Une erreur est survenue", "La cartouche n'a pas pu être créée...");
            });
    };

    const editCartouche = () => {
        const newCartouche = { ...formDataEdit, IDCARTOUCHE: formDataEdit.ID, export_fabdis: true };

        if (checkMandatory(newCartouche) || checkFormat(newCartouche)) return;

        updateCartouche(newCartouche)
            .then((data: any) => {
                initDatas();
                addNotification("success", "Cartouche éditée", "La cartouche a bien été éditée !");
                setOpen(false);
            })
            .catch(() => {
                addNotification("danger", "Une erreur est survenue", "La cartouche n'a pas pu être éditée...");
            });
    };

    const createCartouche = () => {
        // Resets formData if we come from edit
        setEmptyFormData();
        setProductEdit(undefined);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return {
        cartouches,
        productEdit,
        setProductEdit,
        dataStructureContainer,
        formData,
        formDataEdit,
        dataTypes,
        setInputState,
        setInputStateEdit,
        submitNewCartouche,
        editCartouche,
        deleteCart,
        handleClose,
        open,
        setOpen,
        create,
        setCreate,
        showErrors,
        boolInputs,
    };
};

export default useCartouche;
