import { Chip, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";

type MultiSelectInputProps = {
    state: any;
    handleState: (index: number, name: string, value: any, indexDS: number) => void;
    indexParent: number;
    field: any;
    indexInput: number;
    placeholder: string | undefined | null;
};

const MultiSelectInput = (props: MultiSelectInputProps) => {
    const { state, handleState, indexParent, field, indexInput, placeholder } = props;

    const [values, setValues] = useState(state[0] === "" ? [] : state);
    const [currValue, setCurrValue] = useState("");

    const handleKeyUp = (e: any) => {
        if ((e.keyCode == 13 || e.keyCode == 188) && currValue.length) {
            let val = e.target.value;

            if (e.keyCode == 188) {
                val = val.substring(0, e.target.value.length - 1);
            }

            setValues((oldState: any) => [...oldState, val]);
            setCurrValue("");
        }
    };

    const handleChange = (e: any) => {
        setCurrValue(e.target.value);
    };

    const handleDelete = (item: any, index: any) => {
        let arr = [...values];
        arr.splice(index, 1);
        setValues(arr);
    };

    useEffect(() => {
        if (!values) return;
        handleState(indexParent, field.field_name, values, indexInput);
    }, [values]);

    return (
        <Grid sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Grid sx={{ width: "100%" }}>
                <TextField
                    label={field.field_name}
                    sx={{ width: "100%", backgroundColor: "#f4f4f4" }}
                    variant="filled"
                    required={field.mandatory}
                    value={currValue}
                    onChange={handleChange}
                    onKeyUp={handleKeyUp}
                    placeholder={placeholder ?? ""}
                />
            </Grid>
            <Grid container sx={{ flexWrap: "wrap", width: "fit-content", mt: 2 }}>
                {values && values.map((item: any, index: number) => <Chip size="medium" onDelete={() => handleDelete(item, index)} label={item} />)}
            </Grid>
        </Grid>
    );
};

export default MultiSelectInput;

/*
    display: flex;
    flex-direction: column-reverse;
*/
