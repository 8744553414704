import { WidthFull } from "@mui/icons-material";
import React from "react";
import ProductFormComponent from "src/components/Product/Form";
function Product() {
    return (
        <div className="productForm">
            <ProductFormComponent />
        </div>
    );
}

export default Product;
