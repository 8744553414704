import React, { useEffect, useState } from "react";
import "./index.scss";
import { DataStructure, Field } from "src/types/datastructure";
import { getInputByType } from "src/providers/inputProvider";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Grid,
    Typography,
    Tabs,
    Tab,
    ButtonBase,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Modal,
} from "@mui/material";
import MultiInputComponent from "../MultiInputComponent";
import useProduct from "./useProduct";
import { DeleteForever, Edit } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import Fab from "@mui/material/Fab";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import CatalogBreadcrumbs from "src/providers/breadcrumbsProvider";
import useCatalog from "src/components/Catalog/useCatalog";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";

function generateProps(index: number) {
    return {
        ID: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const arraysEqual = (a: any[], b: any[]) => JSON.stringify(a) == JSON.stringify(b);

const productImagePreviewStyle = {
    maxWidth: "200px",
    maxHeight: "200px",
    border: "1px solid black",
};

const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
};

const modalDeleteStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 420,
    height: 180,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const ProductForm = () => {
    const {
        dataStructureContainer,
        steps,
        activeStep,
        handleChange,
        formData,
        setInputState,
        dataTypes,
        updateProduct,
        deleteEncart,
        addEncart,
        goBack,
        goTop,
        saveEncart,
        open,
        setOpen,
        preDeleteEncart,
        deleteModalOpen,
        setDeleteModalOpen,
        encartEdit,
        setEncartEdit,
        deleteEncartSkeleton,
        isEdit,
        setIsEdit,
    } = useProduct();

    const { setCategoryName } = useCatalog();

    const controlGoBack = () => {
        Swal.fire({
            title: "Attention vous allez annuler l’édition et perdrez toute modification. Continuer ?",
            showCancelButton: true,
            confirmButtonText: `Annuler l'édition`,
        }).then((result) => {
            if (result.isConfirmed) {
                goBack();
            }
        });
    };

    const [scrollY, setScrollY] = useState(0);

    const [libelle30, setLibelle30] = useState<string>();
    const [refciale, setRefciale] = useState<string>();

    useEffect(() => {
        if (!formData || !formData.length) return;
        formData.forEach((fd: any) => {
            if (fd.results.length) {
                fd.results.forEach((x: any) => {
                    if (x["LIBELLE30"]) {
                        setLibelle30(x["LIBELLE30"]);
                    }
                    if (x["REFCIALE"]) {
                        setRefciale(x["REFCIALE"]);
                    }
                });
            }
        });
    }, [formData]);

    const location = useLocation();

    const defaultAccordionTabs = dataStructureContainer?.datastructure.filter((ds: any) => ds.editType == "list").map((ds: any) => ds.collection_name) ?? [];

    useEffect(() => {
        window.addEventListener("scroll", (event) => setScrollY(window.scrollY));
    }, []);

    return (
        <div className="ProductForm pim-component">
            <ButtonBase onClick={() => goBack()} sx={{ position: "absolute", left: 20, top: 80 }}>
                <KeyboardBackspaceIcon sx={{ fontSize: 40 }} />
            </ButtonBase>

            {dataStructureContainer && steps && formData && (
                <>
                    <Paper sx={{ pt: 3, mb: 3, mt: 5, minWidth: "80%", position: "sticky", zIndex: 100, top: 80 }}>
                        <Grid sx={{ textAlign: "center" }} className="header-tools">
                            {formData[activeStep]?.totalResult < 2 && !defaultAccordionTabs.includes(dataStructureContainer.datastructure[activeStep].collection_name) && (
                                <Button
                                    variant="contained"
                                    sx={{ mb: 2 }}
                                    onClick={() => {
                                        updateProduct();
                                    }}
                                >
                                    Sauvegarder
                                </Button>
                            )}

                            {dataStructureContainer && defaultAccordionTabs.includes(dataStructureContainer.datastructure[activeStep].collection_name) && (
                                <Button
                                    variant="contained"
                                    sx={{ mb: 2 }}
                                    onClick={() => {
                                        addEncart(activeStep, dataStructureContainer.datastructure[activeStep].collection_name);
                                    }}
                                >
                                    Ajouter une entrée
                                </Button>
                            )}

                            <Button
                                variant="contained"
                                sx={{ mb: 2 }}
                                color="error"
                                onClick={() => {
                                    controlGoBack();
                                }}
                            >
                                Annuler l’édition
                            </Button>
                        </Grid>
                    </Paper>
                    <Grid sx={{ width: "100%" }}>
                        <Paper sx={{ pt: 3, pb: 3, mb: 2, height: "auto" }}>
                            {location.state && <CatalogBreadcrumbs ID={location.state.categ} setCategoryName={setCategoryName} />}
                            <Box sx={{ display: "flex", flexDirection: "row", maxWidth: "60%", justifyContent: "center", alignItems: "center", margin: "auto", py: 3 }}>
                                <Box
                                    sx={{
                                        height: "auto",
                                        t: 2,
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "center",
                                        minHeight: "180px",
                                    }}
                                >
                                    <Box sx={{ px: 5 }}>
                                        {refciale && (
                                            <Typography variant="h4" sx={{ fontSize: 30, textAlign: "left" }}>
                                                {refciale}
                                            </Typography>
                                        )}
                                    </Box>
                                    {libelle30 && <Typography>{libelle30}</Typography>}
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                    <Box sx={{ width: "100%" }}>
                        <Tabs
                            TabIndicatorProps={{ sx: { display: "none" } }}
                            sx={{
                                paddingLeft: "15px",
                                paddingRight: "15px",
                                width: "100%",
                                backgroundColor: "#ffffff",
                                "& .MuiTabs-flexContainer": {
                                    flexWrap: "wrap",
                                },
                            }}
                            value={activeStep}
                            onChange={handleChange}
                            variant="fullWidth"
                            centered
                        >
                            {steps.map((label: any, index: number) => (
                                <Tab
                                    label={label}
                                    key={`tab-${index}`}
                                    className="tab"
                                    sx={{ fontSize: "12px", minWidth: "fit-content" }}
                                    icon={
                                        <img
                                            src={dataStructureContainer.datastructure[index].svg_icon_link}
                                            style={{ height: "30px", width: "30px" }}
                                            className={index === activeStep ? "active-custom-icon" : ""}
                                        />
                                    }
                                    {...generateProps(index)}
                                />
                            ))}
                        </Tabs>

                        {dataStructureContainer.datastructure.map((ds: DataStructure, indexDS: number) => {
                            return (
                                <>
                                    <div
                                        className="formRow"
                                        hidden={false}
                                        id={`simple-tabpanel-${indexDS}`}
                                        aria-labelledby={`simple-tab-${indexDS}`}
                                        role="tabpanel"
                                        key={`tabpanel-${indexDS}`}
                                    >
                                        {activeStep === indexDS && formData[indexDS].results && (
                                            <div className={formData[indexDS].totalResult === 0 ? "grid-0" : ""}>
                                                <TabPanel value={activeStep} index={indexDS}>
                                                    {(formData[indexDS]?.totalResult > 1 ||
                                                        defaultAccordionTabs.includes(dataStructureContainer.datastructure[activeStep].collection_name)) &&
                                                        (formData[indexDS]?.totalResult > 0 ? (
                                                            <TableContainer component={Paper}>
                                                                <Grid sx={{ paddingY: 4, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                    <Typography variant="h5">
                                                                        {dataStructureContainer.datastructure[activeStep].collection_name.split("_")[1]}
                                                                    </Typography>
                                                                </Grid>
                                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            {dataStructureContainer.datastructure[activeStep].fields.map((field: any, ID: number) => {
                                                                                if (field.field_name !== "myFileUpload") {
                                                                                    return <TableCell>{field.field_name}</TableCell>;
                                                                                }
                                                                            })}
                                                                            <TableCell>Éditer</TableCell>
                                                                            <TableCell>Supprimer</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {Array.from(Array(formData[indexDS]?.totalResult).keys()).map((index: number) => {
                                                                            return (
                                                                                <TableRow key={`aa-${index}`}>
                                                                                    {ds.fields.map((field: Field, indexField) => {
                                                                                        if (field.field_name !== "myFileUpload") {
                                                                                            if (field.field_type === "boolean") {
                                                                                                if (formData[activeStep].results[index][field.field_name] === true) {
                                                                                                    return (
                                                                                                        <TableCell>
                                                                                                            <CheckIcon />
                                                                                                        </TableCell>
                                                                                                    );
                                                                                                } else {
                                                                                                    return (
                                                                                                        <TableCell>
                                                                                                            <CloseIcon />
                                                                                                        </TableCell>
                                                                                                    );
                                                                                                }
                                                                                            } else if (field.field_name === "URL" || field.field_name === "URLT") {
                                                                                                return (
                                                                                                    <TableCell>
                                                                                                        {formData[activeStep].results[index][field.field_name] && (
                                                                                                            <ButtonBase
                                                                                                                href={formData[activeStep].results[index][field.field_name]}
                                                                                                                target="_blank"
                                                                                                            >
                                                                                                                <InsertPhotoIcon />
                                                                                                            </ButtonBase>
                                                                                                        )}
                                                                                                        {!formData[activeStep].results[index][field.field_name] && (
                                                                                                            <ButtonBase>
                                                                                                                <ImageNotSupportedIcon />
                                                                                                            </ButtonBase>
                                                                                                        )}
                                                                                                    </TableCell>
                                                                                                );
                                                                                            } else if (field.field_name === "TYPM") {
                                                                                                let subType;
                                                                                                if (formData[indexDS].results[index][field.field_name]) {
                                                                                                    subType =
                                                                                                        formData[indexDS].results[index][field.field_name].split(" - ")[1];
                                                                                                } else {
                                                                                                    subType = "";
                                                                                                }
                                                                                                return <TableCell>{subType}</TableCell>;
                                                                                            } else {
                                                                                                return (
                                                                                                    <TableCell>{formData[indexDS].results[index][field.field_name]}</TableCell>
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    })}
                                                                                    <TableCell sx={{ width: 50 }} align="center">
                                                                                        <ButtonBase
                                                                                            onClick={() => {
                                                                                                setEncartEdit([indexDS, index]);
                                                                                                setOpen(true);
                                                                                                setIsEdit(true);
                                                                                            }}
                                                                                        >
                                                                                            <Edit />
                                                                                        </ButtonBase>
                                                                                        <Modal
                                                                                            open={open && arraysEqual(encartEdit, [indexDS, index])}
                                                                                            onClose={() => {
                                                                                                !isEdit && deleteEncartSkeleton(indexDS, index);
                                                                                                setOpen(false);
                                                                                                setIsEdit(false);
                                                                                            }}
                                                                                            aria-labelledby="modal-modal-title"
                                                                                            aria-describedby="modal-modal-description"
                                                                                        >
                                                                                            <Box sx={modalStyle}>
                                                                                                <MultiInputComponent
                                                                                                    fields={ds.fields}
                                                                                                    state={formData[indexDS].results[index]}
                                                                                                    handleState={setInputState}
                                                                                                    indexDS={indexDS}
                                                                                                    index={index}
                                                                                                    dataTypes={dataTypes}
                                                                                                />

                                                                                                <Box
                                                                                                    sx={{
                                                                                                        mt: 2,
                                                                                                        display: "flex",
                                                                                                        columnGap: 1,
                                                                                                        justifyContent: "center",
                                                                                                        alignItems: "center",
                                                                                                    }}
                                                                                                >
                                                                                                    <Button
                                                                                                        variant="contained"
                                                                                                        onClick={() =>
                                                                                                            saveEncart(
                                                                                                                formData[indexDS].results[index],
                                                                                                                dataStructureContainer.datastructure[activeStep]
                                                                                                                    .collection_name
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        Sauvegarder
                                                                                                    </Button>

                                                                                                    <Button
                                                                                                        variant="contained"
                                                                                                        color="error"
                                                                                                        onClick={() => {
                                                                                                            !isEdit && deleteEncartSkeleton(indexDS, index);
                                                                                                            setOpen(false);
                                                                                                            setIsEdit(false);
                                                                                                        }}
                                                                                                    >
                                                                                                        Annuler
                                                                                                    </Button>
                                                                                                </Box>
                                                                                            </Box>
                                                                                        </Modal>
                                                                                    </TableCell>
                                                                                    <TableCell sx={{ width: 50 }} align="center">
                                                                                        <ButtonBase sx={{ cursor: "pointer" }} onClick={() => preDeleteEncart(indexDS, index)}>
                                                                                            <DeleteForever sx={{ color: "red" }} />
                                                                                        </ButtonBase>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            );
                                                                        })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        ) : (
                                                            <Typography sx={{ p: 5, backgroundColor: "#fff", fontSize: 18 }}>
                                                                Aucune entrée pour la ref {formData[0].results[0].REFCIALE}
                                                            </Typography>
                                                        ))}

                                                    {formData[indexDS]?.totalResult === 1 &&
                                                        !defaultAccordionTabs.includes(dataStructureContainer.datastructure[activeStep].collection_name) && (
                                                            <Paper variant="outlined" sx={{ width: "100%" }}>
                                                                <Grid container sx={{ paddingY: 4, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                    <Typography sx={{ fontSize: "1.5rem !important" }} variant="h3">
                                                                        {dataStructureContainer.datastructure[activeStep].collection_name.split("_")[1]}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid container sx={{ display: "flex", p: 0, width: "100%" }}>
                                                                    {ds.fields.map((field: Field, indexField) =>
                                                                        getInputByType(
                                                                            field,
                                                                            indexField,
                                                                            indexDS,
                                                                            dataTypes,
                                                                            formData[indexDS].results[0][field.field_name],
                                                                            setInputState,
                                                                            0,
                                                                            false
                                                                        )
                                                                    )}
                                                                </Grid>
                                                            </Paper>
                                                        )}
                                                </TabPanel>
                                            </div>
                                        )}

                                        <Modal
                                            open={deleteModalOpen}
                                            onClose={() => setDeleteModalOpen(false)}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                            className="delete-modal"
                                        >
                                            <Box sx={modalDeleteStyle}>
                                                <Typography>Êtes-vous sûr de vouloir supprimer cet encart ?</Typography>

                                                <Button variant="contained" sx={{ mr: 1 }} color="error" onClick={() => setDeleteModalOpen(false)}>
                                                    Annuler
                                                </Button>

                                                <Button
                                                    variant="contained"
                                                    onClick={() => {
                                                        deleteEncart();
                                                        setDeleteModalOpen(false);
                                                    }}
                                                >
                                                    Confirmer
                                                </Button>
                                            </Box>
                                        </Modal>

                                        {activeStep === indexDS && !formData[indexDS].results && <p>Pas de données</p>}
                                    </div>
                                </>
                            );
                        })}
                    </Box>
                </>
            )}

            {scrollY > 100 && (
                <Fab color="primary" aria-label="Go to top" sx={{ position: "fixed", bottom: 20, right: 40, zIndex: 2000 }} onClick={() => goTop()}>
                    <ArrowUpwardIcon />
                </Fab>
            )}

            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={!dataStructureContainer || !formData}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default ProductForm;
