import axios from "axios";
import { useEffect } from "react";

const Dev = () => {
    useEffect(() => {
        var data =
            '{\n    "token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1dWlkIjoiNGI4ZjMxNjktNmMwMS00M2UzLTliNzgtMjBmN2RjNWJkYzdmIiwiaWF0IjoxNjI1MDM5OTMwLCJleHAiOjE2MjU1NTgzMzB9.4ECWNoUxcM3-ce4a8SD-GEntGkrGgtzEaxNKCHGpPyI"\n}';

        var config = {
            method: "post",
            url: "http://localhost:3500/api/getuserinfo",
            headers: {},
            data: data,
        };

        axios(config)
            .then(function (response: any) {})
            .catch(function (error: any) {});
    }, []);

    return <p>Dev</p>;
};

export default Dev;
