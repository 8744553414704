import { useState, useEffect, useContext, MouseEvent } from "react";
import { getBreadcrumbs, getSubProductsAndSubCategsByCategId } from "src/services/api";
import Box from "@mui/material/Box";
import { ChevronRight, ExpandMore, Class, Inventory, FolderOpen, Folder } from "@mui/icons-material";
import { BreadCrumbsContext } from "src/context/breadcumbsContext";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { TreeView, TreeItem, TreeItemContentProps } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Tooltip } from "@mui/material";
import { setBreadcrumbsTree } from "src/features/tree/tree";
import CategoryCreateModal from "src/components/Category/Create";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import DescriptionIcon from "@mui/icons-material/Description";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React from "react";

interface Breadcrumb {
    ID: string;
    category_name: string;
    category_parent?: string;
    crea_date?: number;
    famId?: string;
    famParent?: string;
    niv: number;
    upd_date?: number;
    childrenCategories?: any[];
    childrenProducts?: any[];
}

export interface Category {
    ID: string;
    category_name: string;
    category_parent: string;
    childrenCategories: Category[];
    childrenProducts: Product[];
    niv: number;
}

interface Product {
    idSummaryPdt: number;
    name: string;
    LIBELLE30: string;
    REFCIALE: string;
}

interface Props {
    categories?: Category[];
    expanded?: string;
}

interface CustomContentProps extends TreeItemContentProps {
    myProps: any;
}

const TreeStructure: React.FC<Props> = ({ categories: openedCategs }) => {
    const { value, setValue } = useContext(BreadCrumbsContext);

    const storedTreeValue = useSelector((state: any) => state.tree.tree);
    const storedBcsValue = useSelector((state: any) => state.breadcrumb.breadcrumb);

    const [categories, setCategories] = useState<Category[]>([]);
    const [expanded, setExpanded] = useState<any[]>(["1", ...extractNodeIds(storedTreeValue)]);
    const [selected, setSelected] = useState<string[]>([]);
    const [categoryId, setCategoryId] = useState<string | null>(null);
    const [currentCategId, setCurrentCategId] = useState<string>();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => { }, [expanded]);

    const CustomContent = (props: { label: string; ID: string; categ?: Category }) => {
        return (
            <>
                {props.label}
                <Box className="icons">
                    <IconButton
                        className="category-tree-add-category"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={(e: MouseEvent<HTMLButtonElement>) => {
                            e.stopPropagation();
                            setCategoryId(props.ID);
                        }}
                        edge="start"
                    >
                        <CreateNewFolderOutlinedIcon sx={{ color: "#4C49AC" }} />
                    </IconButton>
                    <IconButton
                        className="category-tree-add-product"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => {
                            navigate("/createproduct", { state: { from: "catalog", categ: props.categ?.ID } });
                            navigate(0);
                        }}
                        edge="start"
                    >
                        <PostAddOutlinedIcon sx={{ color: "#4C49AC" }} />
                    </IconButton>
                </Box>
            </>
        );
    };

    const ProductCustomContent = (props: { label: string; libelle: string; product: any; parent: any }) => {
        return (
            <div
                onClick={() => {
                    handleProductClick(props.product, props.parent);
                }}
            >
                {props.label}
                <Box className="icons">
                    <Tooltip title={props.libelle}>
                        {/* <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            sx={{ zIndex: 99999 }}
                            onClick={(e: any) => e.preventDefault()}
                        > */}
                        <InfoOutlinedIcon sx={{ color: "#4C49AC" }} />
                        {/* </IconButton> */}
                    </Tooltip>
                </Box>
            </div>
        );
    };

    useEffect(() => {
        setCurrentCategId(window.location.href.split("/").at(-1));
    }, []);

    useEffect(() => {
        return () => {
            if (!categories) return;
            // Removes duplicated categories
            let tempCategories: any;
            tempCategories = new Set(categories);
            tempCategories = Array.from(tempCategories);
            dispatch(setBreadcrumbsTree(tempCategories));
        };
    }, [categories]);

    function extractNodeIds(obj: any) {
        if (!obj) return [];
        let nodeIds: any[] = [];

        if (Array.isArray(obj)) {
            obj.forEach((item: any) => {
                if (item.ID && (item.childrenCategories || item.childrenProducts) && storedBcsValue.filter((bc: any) => bc.category_name == item.category_name).length) {
                    nodeIds.push(item.ID);
                }

                if (item.childrenCategories) {
                    item.childrenCategories.forEach((child: any) => {
                        nodeIds = nodeIds.concat(extractNodeIds(child));
                    });
                }
            });
        }

        if (typeof obj === "object") {
            if (obj.ID && (obj.childrenCategories || obj.childrenProducts) && storedBcsValue.filter((bc: any) => bc.category_name == obj.category_name).length) {
                nodeIds.push(obj.ID);
            }

            if (obj.childrenCategories) {
                obj.childrenCategories.forEach((child: any) => {
                    nodeIds = nodeIds.concat(extractNodeIds(child));
                });
            }
        }

        let tempNodesId: any = new Set(nodeIds);
        tempNodesId = Array.from(tempNodesId);
        return tempNodesId;
    }

    useEffect(() => {
        if (storedTreeValue && storedTreeValue.length) {
            setCategories(storedTreeValue);
            setExpanded([...expanded, ...extractNodeIds(storedTreeValue)]);
            return;
        } else {
            value.forEach((breadCrumb: Breadcrumb) => {
                getCategsChildren(breadCrumb);
            });
        }
    }, [value]);

    useEffect(() => {
        let expandedCategs = openedCategs
            ?.filter((categ) => categ.ID)
            .map((categ) => {
                return categ.ID;
            });
        expandedCategs && expandedCategs.length > 0 ? setExpanded([...expanded, ...expandedCategs]) : setExpanded(["1"]);
    }, [openedCategs]);

    const isEmpty = (c: any) => c.childrenCategories?.length > 0 || c.childrenProducts?.length > 0;

    // * Recursive tree rendering function
    function renderTree(data: Category[], isAccueil = false) {
        if (isAccueil) {
            const dataSet = new Set(data);
            data = Array.from(dataSet);
            data = data.sort((a, b) => a.niv - b.niv);
            data = data.find((category) => category.category_name === "Accueil")?.childrenCategories || [];
        } else {
            data = data.sort((a, b) => a.niv - b.niv).filter((category) => category.category_name !== "Accueil");
        }

        return data.map((category: Category, index: number) => {
            let categ: Category = categories.find((c) => c.ID === category.ID) ?? category;

            if (!(categ.childrenProducts || categ.childrenCategories)) {
                return renderTreeItem("category", categ);
            }

            return (
                <Box className="tree-item" key={categ.ID}>
                    <TreeItem
                        nodeId={`${categ.ID}`}
                        label={CustomContent({ label: categ.category_name, ID: categ.ID, categ: categ })}
                        className={"category-tree-item " + (currentCategId === category.ID ? "current " : "")}
                        aria-expanded={isEmpty(categ)}
                        aria-selected={isEmpty(categ)}
                        onClick={(event) => handleCategoryClick(categ)}
                    >
                        {categ.childrenCategories &&
                            categ.childrenCategories.map((categ) => {
                                let childCateg: Category = categories.find((c) => c.ID === categ.ID) ?? categ;
                                return childCateg.childrenCategories ? renderTree([childCateg]) : renderTreeItem("category", categ);
                            })}

                        {categ.childrenProducts && categ.childrenProducts.map((product) => renderTreeItem("product", product, categ))}
                        {!categ.childrenProducts.length && !categ.childrenCategories.length ? "Pas de resultat" : ""}
                    </TreeItem>
                </Box>
            );
        });
    }

    function renderTreeItem(type: string, item: Category | Product, parent?: Category) {
        let itemElement = null;
        switch (type) {
            case "product":
                const product = item as Product;
                itemElement = (
                    <TreeItem
                        nodeId={"" + product.idSummaryPdt}
                        key={product.idSummaryPdt}
                        label={ProductCustomContent({ label: product.REFCIALE, libelle: product.LIBELLE30, product, parent })}
                        // label={product.REFCIALE}
                        icon={<DescriptionIcon />}
                    // onClick={() => {
                    //         handleProductClick(product, parent)
                    // }}
                    />
                );
                break;
            case "category":
                const category = item as Category;
                itemElement = (
                    <Box className="tree-item">
                        <TreeItem
                            className={"category-tree-item"}
                            key={category.ID}
                            nodeId={`${category.ID}`}
                            label={CustomContent({ label: category.category_name, ID: category.ID, categ: category })}
                            icon={<Folder />}
                            onClick={() => handleCategoryClick(category)}
                        />
                    </Box>
                );
                break;
            default:
                itemElement = <TreeItem nodeId="-1" />;
        }

        return itemElement;
    }

    // * Retrieve categs' children function
    function getCategsChildren(category: Category | Breadcrumb) {
        getSubProductsAndSubCategsByCategId(category.ID).then((data: any) => {
            let [newCategories, newProducts] = data;
            newCategories = newCategories.data && Array.isArray(newCategories.data) ? newCategories.data : [];
            newProducts = newProducts.data && Array.isArray(newProducts.data) ? newProducts.data : [];
            let categ = {
                ...category,
                childrenCategories: newCategories,
                childrenProducts: newProducts,
                category_parent: category.category_parent || " ",
                niv: category.niv >= 0 ? category.niv : -1,
            };

            setCategories((categories) => [...categories, categ]);
        });
    }

    function handleCategoryClick(category: Category) {
        const index = expanded.indexOf(category.ID);
        if (index != -1) {
            // Removes it to close it
            setExpanded(expanded.filter((e: any, ID: number) => index !== ID));
        } else {
            // Add its to open it
            getCategsChildren(category);
            setExpanded([...expanded, category.ID]);
        }
    }

    function handleProductClick(product: Product, parent?: Category) {
        if (parent) {
            setValue(expanded);
        }
        navigate(`/product/${product.REFCIALE}`, {
            state: {
                categ: parent?.ID,
            },
        });
        navigate(0);
    }

    return (
        <Box className="tree-wrapper">
            <div>
                <TreeView aria-label="file system navigator" defaultCollapseIcon={<FolderOpen />} defaultExpandIcon={<Folder />} expanded={expanded} selected={selected}>
                    <TreeItem nodeId="1" label={CustomContent({ label: "Acceuil", ID: "accueil" })} aria-expanded="true">
                        {categories && renderTree(categories, true)}
                    </TreeItem>
                </TreeView>
            </div>
            {categoryId && (
                <CategoryCreateModal
                    open={true}
                    handleClose={() => setCategoryId(null)}
                    categoryParentId={categoryId}
                    refreshData={() => {
                        dispatch(setBreadcrumbsTree([]));
                        navigate(0);
                    }}
                />
            )}
        </Box>
    );
};

export default TreeStructure;
