import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import theme from "./theme";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material";

import { Provider } from "react-redux";
import { store } from "./features";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <Router>
                <App />
            </Router>
        </ThemeProvider>
    </Provider>
    // </React.StrictMode>
);
reportWebVitals();
