import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUuid, selectIat, selectExp, selectUserEmail, selectUserAddressName, selectUserPhone } from "../../features/auth/auth";

type UserType = {
    name: string;
    email: string;
    phoneNumber: string;
    uuid: string;
    pictureUrl: string;
};

const useAccount = () => {
    const [user, setUser] = useState<UserType>();
    const uuid = useSelector(selectUuid);
    const iat = useSelector(selectIat);
    const exp = useSelector(selectExp);
    const userEmail = useSelector(selectUserEmail);
    const userAddressName = useSelector(selectUserAddressName);
    const UserPhoneNumber = useSelector(selectUserPhone);

    useEffect(() => {
        setUser({
            name: userAddressName,
            email: userEmail,
            phoneNumber: UserPhoneNumber,
            uuid: uuid,
            pictureUrl: "",
        });
    }, []);

    return {
        user,
    };
};

export default useAccount;
