import axiosClient from "./client";
import axios, { AxiosPromise, CancelTokenSource } from "axios";
import { store } from "../../features";
/*

    CATALOG RELATED

*/

export const addCatalogTree = (category_parent: string, category_name: string) => {
    return axiosClient
        .post(`/manage/addCatalogTree`, {
            category_parent: category_parent === "accueil" ? "" : category_parent,
            category_name,
        })
        .then((response) => response);
};

export const getCatalogTree = (category_parent: string) => {
    return axiosClient
        .post(`/manage/getCatalogTree`, {
            category_parent,
        })
        .then((response) => response);
};

export const setCatalogTree = (category_parent: string, category_name: string, ID: string) => {
    return axiosClient
        .post(`/manage/setCatalogTree`, {
            category_parent,
            category_name,
            ID,
        })
        .then((response) => response);
};

export const deleteCatalogTree = (idCat: string) => {
    return axiosClient
        .post(`/manage/deleteCatalogTree`, {
            idCat,
        })
        .then((response) => response);
};

export const getSubProductsAndSubCategsByCategId = (id: string) => {
    return axios
        .all([
            axiosClient.post(`/manage/getCatalogTree`, {
                category_parent: id,
            }),
            axiosClient.post(`/manage/getCatalogTreePdt`, {
                idCat: id,
            }),
        ])
        .then(axios.spread((categories, products) => [categories, products]));
};

/*

    CAROUCHE RELATED

*/

export const getListCartouche = () => {
    return axiosClient.get(`/getListCartouche`).then((response) => response);
};

export const updateCartouche = (cartouche: any) => {
    return axiosClient.post(`/manage/setCartouche`, { ...cartouche }).then((response) => response);
};

export const addCartouche = (cartouche: any) => {
    return axiosClient.post(`/manage/addCartouche`, { ...cartouche }).then((response) => response);
};

export const deleteCartouche = (IDCARTOUCHE: string) => {
    return axiosClient.post(`/manage/deleteCartouche`, { IDCARTOUCHE: IDCARTOUCHE }).then((response) => response);
};

export const getDataStructure = () => {
    return axiosClient.get(`/getDataStructure`).then((response) => response);
};

export const getCatalogProductTree = (idCat: string) => {
    return axiosClient
        .post(`/manage/getCatalogTreePdt`, {
            idCat,
        })
        .then((response) => response);
};

/*

    PRODUCT RELATED

*/
export const getInfoByTab = (REFCIALE: string, url: any) => {
    return axiosClient
        .post(url, {
            REFCIALE,
        })
        .then((response) => response);
};

export const setPdtInfos = (newPdtInfos: any, url: any, type: string = "data") => {
    if (type == "media") {
        newPdtInfos.fileToUpload = newPdtInfos.file;
        newPdtInfos.myploadedfile = newPdtInfos.file;

        newPdtInfos.file = undefined;
        if (newPdtInfos.TYPM && typeof newPdtInfos.TYPM === "string") {
            newPdtInfos.TYPM = newPdtInfos.TYPM.split(" - ")[1];
        }

        return axios({
            method: "post",
            url: axiosClient.defaults.baseURL + url,
            data: newPdtInfos,
            headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => response);
    } else {
        return axiosClient.post(url, newPdtInfos).then((response) => response);
    }
};

export const addPdtInTree = async (idPdt: string, idCat: string) => {
    return await axiosClient.post("/manage/addPdtInTree", {
        idPdt,
        idCat,
    });
};

export const getAllPdtInfos = async (REFCIALE: string) => {
    const endpoints: string[] = [];
    store.getState().datastructure["datastructure"].datastructure.forEach((ds: any) => {
        const crudApi = ds.crud_api.find((api: any) => api.action === "READ");
        if (crudApi) {
            endpoints.push(crudApi.endpoint);
        }
    });

    const requests = endpoints.map((endpoint: string) => {
        return axiosClient.post(`${endpoint}`, {
            REFCIALE,
        });
    });

    try {
        const responses = await axios.all(requests);
        return responses;
    } catch (error) {
        console.error("Une erreur s'est produite lors de l'exécution des requêtes.", error);
        throw error;
    }
};

export const getInfoInputs = (endpoint: string, refciale: string) => {
    return axiosClient
        .post(`${endpoint}`, {
            REFCIALE: refciale,
        })
        .then((response) => response);
};

export const getBreadcrumbs = (idCat: string) => {
    return axiosClient
        .post(`/manage/getBreadcrumb`, {
            idCat,
        })
        .then((response) => response);
};

export const searchAt = (endpoint: string, value: string) => {
    const payload: any = {};

    switch (endpoint) {
        case "ETIMSearchClass":
            payload.searchString = value;
            break;

        case "searchProduct":
            payload.REFCIALE = value;
            break;

        case "ETIMSearchFeature":
            payload.searchString = value;
            break;

        case "ETIMSearchValue":
            payload.searchString = value;
            break;

        default:
            break;
    }

    return axiosClient.post(`/${endpoint}`, payload).then((response) => response);
};

/*

    SEARCH RELATED

*/

let cancel: CancelTokenSource | null = null;

export const searchApi = (searchVal: string, cancelToken?: CancelTokenSource) => {
    if (cancel) {
        cancel.cancel("Request cancelled by user");
    }

    const source = cancelToken || axios.CancelToken.source();
    cancel = source;

    return axios
        .all([
            axiosClient.post(
                `/searchProduct`,
                {
                    REFCIALE: searchVal,
                },
                { cancelToken: source.token }
            ),
        ])
        .then(axios.spread((products) => [products]))
        .catch((error) => {
            if (axios.isCancel(error)) {
            } else {
            }
        });
};

export const cancelRequest = () => {
    if (cancel) {
        cancel.cancel("Request cancelled by user");
    }
};
