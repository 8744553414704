import { createSlice } from "@reduxjs/toolkit";

type DatastructureType = any;

const initialState: DatastructureType = {
    datastructure: null,
};

const datastructureSlice = createSlice({
    name: "datastructure",
    initialState,
    reducers: {
        setDatastructure: (state, action) => {
            state.datastructure = action.payload;
        },
    },
});

export const { setDatastructure } = datastructureSlice.actions;

export const setDatastructureAction = (ds: any) => {
    return {
        type: "datastructure/setDatastructure",
        payload: ds,
    };
};

export default datastructureSlice.reducer;
