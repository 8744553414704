import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { searchApi, cancelRequest } from "src/services/api";
import { SearchResults } from "src/types/search";

const useSearch = () => {
    const [results, setResults] = useState<Array<any>>();
    const [searchValue, setSearchValue] = useState<string>("");
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        search();
    }, [searchValue]);

    const search = () => {
        if (searchValue.length >= 3) {
            setIsFetching(true);

            // Annuler la recherche précédente
            cancelRequest();

            searchApi(searchValue).then((data) => {
                let searchResults: Array<any> = [];
                if (data) {
                    data.forEach((result: any) => {
                        if (result.data.totalResult) {
                            searchResults.push(result.data.results);
                        }
                    });
                }
                setResults(searchResults);

                setIsFetching(false);
            });
        } else {
            setResults([]);
        }
    };

    const navigateTo = (result: any) => {
        let url;
        if (result.REFCIALE) {
            url = `/product/${result.REFCIALE}`;
        } else {
            url = `/catalog/${result.ID}`;
        }
        setIsVisible(false);
        navigate(url);
        navigate(0);
    };

    return {
        results,
        searchValue,
        setSearchValue,
        isFetching,
        isVisible,
        setIsVisible,
        navigateTo,
    };
};

export default useSearch;
