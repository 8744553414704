// getListCartouche
import { Grid } from "@mui/material";
import React from "react";
import CartoucheComponent from "src/components/Cartouche";
import SideBar from "src/components/CustomAppBar/SideBar";

const Cartouche = () => {
    return (
        <Grid className="catalogpage" sx={{ width: "100%", mt: 5 }}>
            <CartoucheComponent />
        </Grid>
    );
};

export default Cartouche;
