import { Box, TextField, Typography } from "@mui/material";
import "./index.scss";
import useAccount from "./useAccount";

const Account = () => {
    const { user } = useAccount();

    return (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }} className="pim-component">
            <Box sx={{ mt: 10, p: 5, display: "flex", flexDirection: "column", width: "50%", minWidth: "500px" }}>
                <Typography>uuid : {user?.uuid ?? "Non renseigné"}</Typography>
                <TextField disabled value={user?.name ?? "No value"} label="Prénom / Nom" variant="standard" sx={{ width: "100%", mb: 2 }} />
                <TextField disabled value={user?.email ?? "No value"} label="Adresse e-mail" variant="standard" sx={{ width: "100%", mb: 2 }} />
                <TextField disabled value={user?.phoneNumber ?? "No value"} label="Numéro de téléphone" variant="standard" sx={{ width: "100%", mb: 2 }} />
            </Box>
        </Box>
    );
};

export default Account;
