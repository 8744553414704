import { Backdrop, Button, Fade, Grid, Modal, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState, useContext } from "react";
import { setPdtInfos, addPdtInTree, getDataStructure } from "src/services/api";
import { DataStructureType } from "src/types/datastructure";
import { getInputByType, getInputValueByType } from "src/providers/inputProvider";
import "./index.scss";
import Paper from "@mui/material/Paper";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "@mui/material";
import { id } from "date-fns/locale";
import CatalogBreadcrumbs from "src/providers/breadcrumbsProvider";
import useCatalog from "src/components/Catalog/useCatalog";
import { BreadCrumbsContext } from "src/context/breadcumbsContext";
import Breadcrumbs from "src/components/Breadcrumbs";
import { ReactReduxContext, useSelector } from "react-redux";
import Breadcrumb from "src/features/breadcrumb/breadcrumb";
import useNotifications from "src/pages/Notifications/useNotifications";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #00000025",
    boxShadow: 24,
    p: 4,
};

const ProductCreate = () => {
    const [dataStructureContainer, setDataStructureContainer] = useState<any>();
    const [currentDataStructure, setCurrentDataStructure] = useState<any>();
    const [dataTypes, setDataTypes] = useState<any>();
    const [formData, setFormData] = useState<any>();
    const [modalState, setModalState] = useState(false);
    const handleOpen = () => setModalState(true);
    const handleClose = () => setModalState(false);
    const [mandatoryParams, setMandatoryParams] = useState<any>();
    const [showErrors, setShowErrors] = useState<any>(true);

    // Redux use to explore
    const { store } = useContext(ReactReduxContext);
    let { breadcrumb: breadCrumbs } = store.getState().breadcrumb;

    const { addNotification } = useNotifications();

    const { ID, setCategoryName } = useCatalog();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        getDataStructure().then((ds) => {
            let dss = ds.data;
            dss.datastructure = dss.datastructure.filter((elem: any) => elem.primary && elem.related_data == "PRODUCT");
            setCurrentDataStructure(dss.datastructure[0]);
            setDataStructureContainer(dss.datastructure[0].fields);

            let tempMandatoryParams: Array<any> = [];
            dss.datastructure.forEach((ds: any, dsId: number) => {
                ds.fields.forEach((_: any) => {
                    if (_.mandatory && _.field_type !== "boolean") {
                        tempMandatoryParams.push(_.field_name);
                    }
                });
            });
            setMandatoryParams(tempMandatoryParams);

            setDataTypes({
                dataUnits: dss.dataUnits,
                dataMediaTypes: dss.dataMediaTypes,
                dataAvantages: dss.dataAvantages,
            });
        });
    }, []);

    useEffect(() => {
        if (!dataStructureContainer) return;
        let tempForm: any = {};
        dataStructureContainer.forEach((dataStructure: any) => {
            let value = getInputValueByType(dataStructure);
            tempForm[dataStructure.field_name] = value;
        });
        setFormData(tempForm);
    }, [dataStructureContainer]);

    const setInputState = async (index: any, name: string, value: any) => {
        let temp = { ...formData };
        temp[name] = value;
        setFormData(temp);
    };

    const createProduct = async () => {
        const goodProduct = { ...formData };
        setShowErrors(false);

        let missingFields: Set<any> = new Set();

        mandatoryParams.forEach((mandatoryParamId: string) => {
            if (!goodProduct[mandatoryParamId] && goodProduct[mandatoryParamId] !== false) {
                missingFields.add(mandatoryParamId);
            }
        });

        if (missingFields.size) {
            addNotification(
                "warning",
                "Certains champs ne sont pas remplis",
                `Afin d'update le produit, il vous faut remplir ces champs : ${Array.from(missingFields).join(", ")}`,
                5000
            );
            return;
        }

        setPdtInfos(goodProduct, currentDataStructure.crud_api.find((e: any) => e.action === "CREATE")!.endpoint, currentDataStructure.type).then((data: any) => {
            addPdtInTree(data.data.record.ID, breadCrumbs.at(-1).ID).then((data2: any) => {
                if (data.data.record.REFCIALE) {
                    navigate(`/product/${data.data.record.REFCIALE}`, {
                        state: {
                            from: "createproduct",
                            categ: location.state.categ,
                        },
                    });
                } else {
                    navigate("/");
                }
            });
        });

        /*addInfoPdtComm(goodProduct).then((data: any) => {
            addPdtInTree(goodProduct.REFCIALE, breadCrumbs.at(-1).ID).then((data2: any) => {
                if (data.data.record.REFCIALE) {
                    navigate(`/product/${data.data.record.REFCIALE}`, {
                        state: {
                            from: "createproduct",
                            categ: location.state.categ,
                        },
                    });
                } else {
                    navigate("/");
                }
            });
        });*/
    };

    useEffect(() => {}, [formData]);

    function updateProduct() {
        throw new Error("Function not implemented.");
    }

    function controlGoBack() {
        throw new Error("Function not implemented.");
    }

    function addEncart(activeStep: any, collection_name: any): void {
        throw new Error("Function not implemented.");
    }

    return (
        <>
            <Grid className="ProductForm ProductFormHeader">
                <Paper sx={{ pt: 3, pb: 3, mb: 0, mt: 5, minWidth: "80%", zIndex: 100, textAlign: "center" }}>
                    {location.state?.from === "catalog" && (
                        <Box sx={{ mt: 0, mb: 2, ml: "auto", mr: "auto", width: "max-content" }}>
                            {location.state && <CatalogBreadcrumbs ID={location.state.categ} setCategoryName={setCategoryName} />}
                        </Box>
                    )}
                    <Typography variant="h6" component="h2">
                        Création de produit
                    </Typography>
                    <Typography sx={{ mt: 2, mb: 4 }}>Pour la création du produit, veuillez remplir les champs nécessaires à sa création.</Typography>
                    <Box sx={{ mt: 2, display: "flex", columnGap: 1 }}>
                        <Button variant="contained" sx={{ mb: 2 }} onClick={createProduct}>
                            Créer le produit
                        </Button>{" "}
                        <Button
                            variant="contained"
                            sx={{ mb: 2 }}
                            color="error"
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            Annuler
                        </Button>
                    </Box>
                </Paper>
            </Grid>

            <Grid className="ProductForm createForm pim-component">
                <Paper sx={{ mt: 2 }} variant="outlined">
                    <Grid container sx={{ display: "flex" }}>
                        {dataStructureContainer &&
                            formData &&
                            dataStructureContainer.map((field: any, indexField: number) => {
                                return getInputByType(field, indexField, 0, dataTypes, formData[field.field_name], setInputState, 0, false, "create", showErrors);
                            })}
                    </Grid>
                </Paper>
            </Grid>

            <Modal open={modalState} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Fade in={modalState}>
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Création impossible
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Veuillez remplir tout les champs du formulaire
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default ProductCreate;
