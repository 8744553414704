import React, { useEffect, useState } from "react";
import "./stats.scss";
import Button from "@mui/material/Button";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    PointElement,
    Legend,
    LineElement,
    ArcElement,
    RadialLinearScale,
    ChartData,
} from "chart.js";
import { Bar, Pie, PolarArea, Line } from "react-chartjs-2";
import { getStats, getListGamme, getStatsRefMultiCol, getListMarque } from "src/services/api/stats";
import { arrayBuffer } from "stream/consumers";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, ArcElement, Tooltip, PointElement, LineElement, Legend, RadialLinearScale);
export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: "top" as const,
        },
        title: {
            display: true,
            text: "Dashboard Scoring",
        },
    },
};
export const optionsHorizontal = {
    responsive: true,
    indexAxis: "y" as const,
    // maintainAspectRatio: false,
    plugins: {
        legend: {
            position: "top" as const,
        },
        title: {
            display: true,
            text: "Dashboard Scoring",
        },
    },
};

// type Parent = {
//     labels: string;
//     datasets: Dataset[];
// };
// type Dataset = {
//     label: string;
//     data: number;
//     backgroundColor: string;
// };
function Stats() {
    const [finalData, setFinalData] = useState<ChartData<"bar">>({ labels: [], datasets: [] });
    const [listGamme, setListGamme] = useState<ChartData<"bar">>({
        labels: [],
        datasets: [],
    });
    const [marque, setMarque] = useState([]);
    const [inputMarque, setInputMarque] = useState([]);
    const [gamme, setGamme] = useState();

    function random_rgba() {
        var o = Math.round,
            r = Math.random,
            s = 255;
        return "rgba(" + o(r() * s) + "," + o(r() * s) + "," + o(r() * s) + ",0.7)";
    }
    useEffect(() => {
        getListMarque().then((datab: any) => {
            setMarque(datab.data["MARQUE"]);
        });
        getListGamme(inputMarque).then((dataMarque: any) => {
            // Pour l'instant je met a 0 puis ce sera un select plus tard
            setGamme(dataMarque.data["GAMME"][0]);
        });
    }, [inputMarque]);
    useEffect(() => {
        getStats(gamme).then((data: any) => {
            let tempData: any = [];
            let newLabels: any = [];
            let temp = { labels: [], datasets: [{ label: "Completion rate in %", data: [], backgroundColor: random_rgba() }] };
            // temp.datasets.push();
            data.data.results.slice([0], [10]).map((collection: any) => {
                let initialValue = 0;
                let totalValues: number = collection["COLLECTION"].reduce((total: any, currentValue: any) => total + currentValue.nbDocColl, initialValue);

                newLabels.push(collection["REFCIALE"]);
                tempData.push(totalValues);
                temp.datasets[0].data = tempData;
                temp.labels = newLabels;

                setFinalData(temp);
            });
        });
    }, [gamme]);
    useEffect(() => {
        getStatsRefMultiCol(finalData.labels ? finalData.labels.slice(0, 2) : ["TLM1T45P", "MFP6ARP"]).then((datan: any) => {
            let temp = { ...listGamme };
            temp.datasets.push({ label: "ListGamme", data: [], backgroundColor: random_rgba() });
            datan.data.results[0]["COLLECTION"].map((dataNum: any) => {
                temp.datasets[0].data.push(dataNum[Object.keys(dataNum)[0]]);
                let label = Object.keys(dataNum);
                temp.labels?.push(label[0]);
                setListGamme(temp);
            });
        });
    }, [finalData]);
    function printData(dataTest: any) {
        setInputMarque(dataTest);
        alert("Changement en cours");
    }
    return (
        <div className="Stats pim-component">
            <div className="row">
                {finalData.datasets.length !== 0 ? (
                    <Bar className="first" redraw={true} options={options} data={finalData} />
                ) : (
                    <div className="bars">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                )}
            </div>

            <div className="row">
                <div>
                    {listGamme.datasets.length !== 0 ? (
                        <Bar redraw={true} options={optionsHorizontal} data={listGamme} />
                    ) : (
                        <div className="bars">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    )}
                </div>
            </div>
            <div className="row">
                {marque.length !== 0 ? (
                    <div>
                        <h3>Marque:</h3>
                        {marque.map((data) => {
                            return (
                                <Button variant="contained" onClick={() => printData(data)}>
                                    {data}
                                </Button>
                            );
                        })}
                    </div>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        </div>
    );
}

export default Stats;
