import { BreadCrumbsContext } from "src/context/breadcumbsContext";
import CatalogComponent from "src/components/Catalog";
import { Grid } from "@mui/material";

const Catalog = () => {
    return (
        <Grid sx={{ width: "100%" }}>
            <BreadCrumbsContext.Consumer>
                {({ value, setValue }) => {
                    return (
                        <>
                            <CatalogComponent />
                        </>
                    );
                }}
            </BreadCrumbsContext.Consumer>
        </Grid>
    );
};

export default Catalog;
