import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ProductListItem, TreeItem } from "src/types/tree";
import { getCatalogProductTree, getCatalogTree } from "src/services/api";
import { useNavigate } from "react-router";
import useNotifications from "src/pages/Notifications/useNotifications";

const useCatalog = () => {
    const [treeStructure, setTreeStructure] = useState<TreeItem[]>();
    const [treeProductsStructure, setTreeProductsStructure] = useState<ProductListItem[]>();
    const [categoryName, setCategoryName] = useState<string>("");
    const [productsLoading, setProductsLoading] = useState<boolean>(true);
    const [categoriesLoading, setCategoriesLoading] = useState<boolean>(true);
    let { ID } = useParams();
    const { addNotification } = useNotifications();
    const navigate = useNavigate();

    // Create category
    const [openCategory, setOpenCategory] = useState(false);
    const handleOpenCategory = () => setOpenCategory(true);
    const handleCloseCategory = () => setOpenCategory(false);

    // Update category
    const [openUpdateCategory, setOpenUpdateCategory] = useState(false);
    const handleOpenUpdateCategory = () => setOpenUpdateCategory(true);
    const handleCloseUpdateCategory = () => setOpenUpdateCategory(false);

    // Delete category
    const [openDeleteCategory, setOpenDeleteCategory] = useState(false);
    const handleOpenDeleteCategory = () => setOpenDeleteCategory(true);
    const handleCloseDeleteCategory = () => setOpenDeleteCategory(false);

    useEffect(() => {
        if (!ID) {
            loadData();
            setProductsLoading(false);
            return;
        }
        getCatalogProductTree(ID)
            .then((tree) => {
                if (tree.data.length > 0) {
                    setTreeProductsStructure(tree.data);
                } else {
                    // addNotification("info", "Pas de produits liés", `La catégorie ${categoryName} n'a pas de produits liés`);
                }
            })
            .catch(() => {
                addNotification("danger", "Une erreur est survenue", `Une erreur est survenue lors de la récupération de produits liés à la catégorie ${categoryName}`);
            })
            .then(() => {
                setProductsLoading(false);
            });
        loadData();
    }, []);

    const loadData = () => {
        //Si on entre un id, alors on cherches les sous-categs correspondantes
        getCatalogTree(ID || "")
            .then((tree) => {
                if (tree.data.length > 0) {
                    setTreeStructure(tree.data);
                } else {
                    // addNotification("info", "Pas de catégorie(s) enfant", `La catégorie ${categoryName} n'a pas de catégorie(s) enfant(s)`);
                }
            })
            .catch(() => {
                addNotification("danger", "Une erreur est survenue", `Une erreur est survenue lors de la récupération de catégorie enfant à la catégorie ${categoryName}`);
            })
            .then(() => {
                setCategoriesLoading(false);
            });
    };

    const handleCategoryName = (categName: string) => {
        setCategoryName(categName);
    };

    const reloadAfterUpdateCategory = () => {
        navigate(0);
    };

    const goToProductCreate = () => {
        navigate("/createproduct", {
            state: {
                from: "catalog",
            },
        });
    };

    const backToParentCateg = () => {
        navigate("/catalog", {
            state: {
                from: "catalog",
            },
        });
        navigate(0);
    };

    const deletePdt = (ID: string) => {};

    return {
        ID,
        setCategoryName,
        openCategory,
        handleCloseCategory,
        handleOpenDeleteCategory,
        handleOpenUpdateCategory,
        handleOpenCategory,
        categoryName,
        openUpdateCategory,
        handleCloseUpdateCategory,
        reloadAfterUpdateCategory,
        openDeleteCategory,
        handleCloseDeleteCategory,
        backToParentCateg,
        treeStructure,
        goToProductCreate,
        treeProductsStructure,
        loadData,
        productsLoading,
        categoriesLoading,
        deletePdt,
    };
};

export default useCatalog;
