import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/auth";
import breadcrumbReducer from "./breadcrumb/breadcrumb";
import treeReducer from "./tree/tree";
import datastructureReducer from "./datastructure/datastructure";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import { combineReducers } from "redux";

const persistConfig = {
    key: "root",
    storage,
};

const rootReducer = combineReducers({
    auth: authReducer,
    breadcrumb: breadcrumbReducer,
    tree: treeReducer,
    datastructure: datastructureReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export const persistor = persistStore(store);
