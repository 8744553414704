import React from "react";
import HomeComponent from "../../components/Home/index";
function Home() {
    return (
        <div>
            <HomeComponent />
        </div>
    );
}

export default Home;
