import React from "react";
import { useLocation } from "react-router-dom";
import ProductCreate from "src/components/Product/Create";
import { BreadCrumbsContext } from "src/context/breadcumbsContext";

function ProductCreatePage() {
    const { state } = useLocation();
    return (
        <div>
            <BreadCrumbsContext.Consumer>
                {({ value, setValue }) => {
                    return (
                        <>
                            <ProductCreate />
                        </>
                    );
                }}
            </BreadCrumbsContext.Consumer>
        </div>
    );
}

export default ProductCreatePage;
