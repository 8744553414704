import "./index.scss";
import Typography from "@mui/material/Typography";
import { Button, ClickAwayListener } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Link, Drawer } from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import SendIcon from "@mui/icons-material/Send";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useEffect, useRef, useState } from "react";
import { getSubProductsAndSubCategsByCategId } from "src/services/api";
import TreeStructure, { Category } from "../TreeStructure";
import { useLocation, useNavigate } from "react-router-dom";
import DatasetIcon from "@mui/icons-material/Dataset";

interface Breadcrumb {
    ID: string;
    category_name: string;
    category_parent?: string;
    crea_date?: number;
    famId?: string;
    famParent?: string;
    niv?: number;
    upd_date?: number;
    childrenCategories?: any[];
    childrenProducts?: any[];
}

const SideBar = (props: { bcs: Breadcrumb[]; isDrawerOpen: boolean; closeDrawer: any }) => {
    const { isDrawerOpen, closeDrawer } = props;
    const breadcrumbs = props.bcs;
    const children: { [key: string]: Breadcrumb[] } = {};
    const location = useLocation();
    const navigate = useNavigate();
    const [drawerWidth, setDrawerWidth] = useState(500);
    const [isResizing, setIsResizing] = useState(false);

    const drawerRef = useRef(null);

    const handleMouseMoving = (e: any) => ((drawerRef!.current! as any).children[0].style!.width = `${e.clientX}px`);

    const handleEvent = (event: any) => {
        if (event.type === "mousedown" && !isResizing) {
            window.addEventListener("mousemove", handleMouseMoving, true);
            setIsResizing(true);
        } else {
            window.removeEventListener("mousemove", handleMouseMoving, true);
            setIsResizing(false);
        }
    };

    const styles = {
        dragger: {
            width: "5px",
            cursor: "ew-resize",
            padding: "4px 0 0",
            borderTop: "1px solid #ddd",
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            zIndex: "100",
            backgroundColor: "#f4f7f9",
        },
    };

    // const handleMousedown = (e) => {
    //     setIsResizing({ isResizing: true, lastDownX: e.clientX });
    // };

    return (
        <Drawer
            variant="persistent"
            className="sidebar"
            anchor="left"
            open={isDrawerOpen}
            onClose={() => closeDrawer()}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                    resize: "horizontal",
                },
            }}
            ref={drawerRef}
        >
            {/* <div
                id="dragger"
                onMouseDownCapture={(event) => handleMousedown(event)}
                className={""}
            /> */}
            <Button className="buttonSidebar" href="/">
                <AppsIcon></AppsIcon>
                <Typography>Dashboard</Typography>
                <ArrowForwardIosIcon />
            </Button>

            <Button
                className="buttonSidebar selected"
                onClick={() => {
                    navigate("/catalog", { state: { from: "sidebar" } });
                    closeDrawer();
                }}
            >
                <ViewInArOutlinedIcon></ViewInArOutlinedIcon>
                <Typography>Produits</Typography>
                <ArrowForwardIosIcon />
            </Button>
            {(location.pathname.startsWith("/catalog") ||
                location.pathname.startsWith("/product") ||
                location.pathname.startsWith("/createproduct") ||
                location.pathname.startsWith("/")) && <TreeStructure categories={breadcrumbs as unknown as Category[]} />}
            <Button className="buttonSidebar" href="#">
                <SendIcon></SendIcon>
                <Typography>CCTP</Typography>
                <ArrowForwardIosIcon />
            </Button>
            <Button className="buttonSidebar" href="/cartouche">
                <DatasetIcon />
                <Typography>Cartouche</Typography>
                <ArrowForwardIosIcon />
            </Button>

            <Button className="buttonSidebar" href="#">
                <MenuOutlinedIcon></MenuOutlinedIcon>
                <Typography>FABDIS</Typography>
                <ArrowForwardIosIcon />
            </Button>

            <div onMouseDown={handleEvent} onMouseUp={handleEvent} className="drawer-drag"></div>
        </Drawer>
    );
};

export default SideBar;
