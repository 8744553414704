import { Button, ButtonBase, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { Edit, DeleteForever, AddToPhotos, CreateNewFolder, FolderOpen } from "@mui/icons-material";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import { ProductListItem, TreeItem } from "src/types/tree";
import CatalogBreadcrumbs from "src/providers/breadcrumbsProvider";
import "./index.scss";
import CategoryCreateModal from "../Category/Create";
import CategoryUpdateModal from "../Category/Update";
import CategoryDeleteModal from "../Category/Delete";
import useCatalog from "./useCatalog";
import { RotatingLines } from "react-loader-spinner";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import tree from "src/features/tree/tree";

const Catalog = () => {
    const {
        ID,
        setCategoryName,
        openCategory,
        handleCloseCategory,
        handleOpenDeleteCategory,
        handleOpenUpdateCategory,
        handleOpenCategory,
        categoryName,
        openUpdateCategory,
        handleCloseUpdateCategory,
        reloadAfterUpdateCategory,
        openDeleteCategory,
        handleCloseDeleteCategory,
        backToParentCateg,
        treeStructure,
        goToProductCreate,
        treeProductsStructure,
        loadData,
        productsLoading,
        categoriesLoading,
        deletePdt,
    } = useCatalog();

    const navigate = useNavigate();

    return (
        <Grid container className="Catalog pim-component" direction="column" sx={{ width: "100%" }}>
            <Grid>
                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                    <CatalogBreadcrumbs ID={ID} setCategoryName={setCategoryName} />
                </Grid>
                <Grid container sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                    <>
                        <ButtonBase onClick={handleOpenUpdateCategory}>
                            <Tooltip title={`Éditer la catégorie ${categoryName}`}>
                                <Edit sx={{ color: "#4C49AC", fontSize: 30, mr: 1 }} />
                            </Tooltip>
                        </ButtonBase>
                        <CategoryUpdateModal
                            open={openUpdateCategory}
                            handleClose={handleCloseUpdateCategory}
                            categoryParentId={ID ?? ""}
                            reload={reloadAfterUpdateCategory}
                        />

                        <ButtonBase onClick={handleOpenCategory}>
                            <Tooltip title="Créer une catégorie à cet emplacement">
                                <CreateNewFolder sx={{ color: "#4C49AC", fontSize: 30, mr: 1 }} />
                            </Tooltip>
                        </ButtonBase>
                        <CategoryCreateModal open={openCategory} handleClose={handleCloseCategory} categoryParentId={ID ?? ""} refreshData={loadData} />

                        <ButtonBase onClick={handleOpenDeleteCategory}>
                            <Tooltip title={`Supprimer la catégorie ${categoryName}`}>
                                <DeleteForever sx={{ color: "#4C49AC", fontSize: 30, mr: 1 }} />
                            </Tooltip>
                        </ButtonBase>
                        <CategoryDeleteModal
                            open={openDeleteCategory}
                            handleClose={handleCloseDeleteCategory}
                            categoryParentId={ID ?? ""}
                            backToParentCateg={backToParentCateg}
                        />
                    </>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ display: "flex", flexWrap: "wrap", width: "100%", mt: 1, mb: 2, flexDirection: "column", alignItems: "start" }}>
                {treeStructure && (
                    <Grid container spacing={2} sx={{ display: "flex", flexWrap: "wrap", width: "100%", mt: 2, mb: 2, justifyContent: "center" }}>
                        {treeStructure.map((treeItem: TreeItem, indexTree: any) => {
                            return (
                                <Grid item key={`grid-${indexTree}`}>
                                    <ButtonBase sx={{ p: 1, w: "100%", backgroundColor: "#fff", borderRadius: 1 }} href={"/catalog/" + treeItem.ID}>
                                        <FolderOpen sx={{ pr: 1, fontSize: 20 }} />
                                        <Typography sx={{ fontSize: 18 }} color="text.secondary">
                                            {treeItem.category_name}
                                        </Typography>
                                    </ButtonBase>
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
                {!treeStructure && !categoriesLoading && (
                    <Typography
                        sx={{
                            display: "block",
                            width: "100%",
                            height: "100%",
                            fontSize: 18,
                            color: "lightgrey",
                            mt: 3,
                        }}
                    >
                        La catégorie {categoryName} n'a pas de catégories enfants
                    </Typography>
                )}
                {!treeStructure && categoriesLoading && (
                    <div
                        className="centerItem"
                        style={{
                            marginTop: "8px",
                        }}
                    >
                        <CircularProgress />
                    </div>
                )}
            </Grid>
            <Grid container spacing={2} sx={{ display: "flex", flexWrap: "wrap", width: "100%", flexDirection: "column", alignItems: "start" }}>
                <Grid sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "flex-end", mt: 3, mb: 2 }}>
                    <Typography>Liste des produits</Typography>
                    <Button variant="contained" sx={{ p: 1 }} onClick={goToProductCreate}>
                        <AddToPhotos sx={{ mr: 1, fontSize: 20 }} />
                        <Typography sx={{ fontSize: 16 }}>Créer un produit</Typography>
                    </Button>
                </Grid>

                <TableContainer component={Paper} sx={{ minHeight: "124px" }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Photo</TableCell>
                                <TableCell>REFCIALE</TableCell>
                                <TableCell align="right">GTIN</TableCell>
                                <TableCell align="right">LIBELLE80</TableCell>
                                <TableCell align="right">Éditer</TableCell>
                                <TableCell align="right">Supprimer</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {treeProductsStructure &&
                                treeProductsStructure.map((treeItem: ProductListItem, indexTree) => {
                                    return (
                                        <TableRow key={`table-${indexTree}`} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                            <TableCell>
                                                <NoPhotographyIcon />
                                            </TableCell>
                                            <TableCell>{treeItem.REFCIALE}</TableCell>
                                            <TableCell align="right">{treeItem.GTIN13}</TableCell>
                                            <TableCell align="right">{treeItem.LIBELLE80}</TableCell>
                                            <TableCell sx={{ width: 50 }} align="right">
                                                <ButtonBase
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        navigate(`/product/${treeItem.REFCIALE}`, {
                                                            state: {
                                                                categ: ID,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    <Edit sx={{ color: "#4C49AC" }} />
                                                </ButtonBase>
                                            </TableCell>
                                            <TableCell sx={{ width: 50 }} align="right">
                                                <ButtonBase sx={{ cursor: "pointer" }} onClick={() => deletePdt(treeItem.idSummaryPdt)}>
                                                    <DeleteForever sx={{ cursor: "pointer", color: "red" }} />
                                                </ButtonBase>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {!treeProductsStructure && (
                                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                    <TableCell colSpan={99}>
                                        {productsLoading && <CircularProgress />}
                                        {!productsLoading && (
                                            <Typography
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    height: "100%",
                                                    width: "100%",
                                                    fontSize: 18,
                                                    color: "lightgrey",
                                                }}
                                            >
                                                Il n'y a pas de produits dans la catégorie {categoryName}
                                            </Typography>
                                        )}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {/* {!treeProductsStructure && (

            )} */}
        </Grid>
    );
};

export default Catalog;
