import "./index.scss";
import { Edit, DeleteForever } from "@mui/icons-material";
import { Button, ButtonBase, Grid, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { getInputByType } from "src/providers/inputProvider";
import { CartoucheType } from "src/types/cartouche";
import { Field } from "src/types/datastructure";
import useCartouche from "./useCartouche";
import Box from "@mui/material/Box";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
};

const Cartouche = () => {
    const {
        cartouches,
        productEdit,
        setProductEdit,
        dataStructureContainer,
        formData,
        formDataEdit,
        dataTypes,
        setInputState,
        setInputStateEdit,
        submitNewCartouche,
        editCartouche,
        deleteCart,
        handleClose,
        open,
        setOpen,
        create,
        setCreate,
        showErrors,
        boolInputs,
    } = useCartouche();

    return (
        <div className="cartouches-crud pim-component">
            <Grid>
                {cartouches && (
                    <>
                        <Paper sx={{ pt: 3, mb: 3, mt: 1, minWidth: "80%", position: "sticky", zIndex: 100, top: 80 }}>
                            <Grid sx={{ textAlign: "center" }} className="header-tools">
                                <Button
                                    variant="contained"
                                    sx={{ mb: 2, mr: 2 }}
                                    color={create ? "error" : "primary"}
                                    onClick={() => {
                                        setCreate(!create);
                                    }}
                                >
                                    {create ? "Retirer la cartouche" : "Créer une cartouche"}
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{ mb: 2 }}
                                    disabled={!create}
                                    onClick={() => {
                                        create && submitNewCartouche();
                                    }}
                                >
                                    Sauvegarder
                                </Button>
                            </Grid>
                        </Paper>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {Object.keys(cartouches[0])
                                            .sort()
                                            .map((key: string, indexKey: number) => {
                                                return <TableCell key={`key-${indexKey}`}>{key}</TableCell>;
                                            })}
                                        <TableCell>Éditer</TableCell>
                                        <TableCell>Supprimer</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {cartouches.map((cartouche: CartoucheType, index: number) => {
                                        return (
                                            <TableRow key={`aa-${index}`}>
                                                {Object.keys(cartouche)
                                                    .sort()
                                                    .map((cartoucheField: any, indexField: number) => {
                                                        return (
                                                            <TableCell key={`cartouche-${indexField}`}>
                                                                <Typography>
                                                                    {cartouche[cartoucheField as keyof CartoucheType] === true && <CheckIcon />}
                                                                    {cartouche[cartoucheField as keyof CartoucheType] === false && <CloseIcon />}

                                                                    {cartouche[cartoucheField as keyof CartoucheType] && cartoucheField.trim().toLowerCase() === "eagle"
                                                                        ? "Pas de donnée"
                                                                        : cartouche[cartoucheField as keyof CartoucheType]}
                                                                </Typography>
                                                            </TableCell>
                                                        );
                                                    })}
                                                <TableCell sx={{ width: 50 }} align="right">
                                                    <ButtonBase
                                                        onClick={() => {
                                                            setProductEdit(index);
                                                            setOpen(true);
                                                        }}
                                                    >
                                                        <Edit />
                                                    </ButtonBase>
                                                </TableCell>
                                                <TableCell sx={{ width: 50 }} align="right">
                                                    <ButtonBase sx={{ cursor: "pointer" }} onClick={() => deleteCart(cartouche.ID)}>
                                                        <DeleteForever sx={{ color: "red" }} />
                                                    </ButtonBase>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}

                {dataStructureContainer && formData && create && (
                    <>
                        <Paper className="cartouche" variant="outlined" sx={{ my: 5 }}>
                            <Box>
                                {dataStructureContainer.map((field: Field, indexField: number) => {
                                    return getInputByType(field, indexField, 0, dataTypes, formData[field.field_name], setInputState, 0, undefined, undefined, showErrors);
                                })}
                            </Box>
                        </Paper>

                        {/* <Fab color="primary" aria-label="add" sx={{ position: "fixed", bottom: 20, right: 40, zIndex: 2000 }} onClick={submitNewCartouche}>
                            <SaveIcon />
                        </Fab> */}
                    </>
                )}

                {(productEdit || productEdit === 0) && formDataEdit && open && (
                    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            {/* <p>Éditer la cartouche {cartouches![productEdit].ID}</p> */}
                            {dataStructureContainer.map((field: Field, indexField: number) => {
                                return getInputByType(
                                    field,
                                    indexField,
                                    0,
                                    dataTypes,
                                    formDataEdit[field.field_name as unknown as any],
                                    setInputStateEdit,
                                    0,
                                    undefined,
                                    undefined
                                );
                            })}
                            <Button variant="contained" sx={{ mb: 2, display: "flex", mx: "auto", mt: 2 }} onClick={editCartouche}>
                                Modifier la cartouche
                            </Button>
                        </Box>
                    </Modal>
                )}
            </Grid>
        </div>
    );
};

export default Cartouche;
