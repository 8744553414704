import { useEffect } from "react";
import "./App.scss";
import { useState } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import CustomAppBar from "./components/CustomAppBar";
import { Catalog, Home, Product, Error, Login, Dev } from "./pages";
import { BreadCrumbsContext } from "./context/breadcumbsContext";
import { Breadcrumb } from "./context/breadcumbsContext";
import Stats from "./components/Stats/Stats";
import Cartouche from "./pages/Cartouche";
import ProductCreatePage from "./pages/ProductCreate";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { toLogin, setFirstLogin } from "src/features/auth/auth";
import Account from "./pages/Account";
const defaultHomeBreadcrumb: Breadcrumb = {
    category_name: "Accueil",
    ID: "",
    niv: -1,
};

const App = () => {
    const [value, setValue2] = useState<Breadcrumb[]>([defaultHomeBreadcrumb]);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const isFirstLogin = useSelector((state: any) => state.auth.isFirstLogin);
    const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
    const closeDrawer = () => {
        setIsDrawerOpen(false);
    };
    const setValue = (breadcrumb: Breadcrumb[]) => {
        setValue2([defaultHomeBreadcrumb, ...breadcrumb]);
    };

    const dispatch = useDispatch();
    const isLog = useSelector((state: any) => state.auth.isLog);
    const hasExpired = useSelector((state: any) => state.auth.hasExpired);
    const navigate = useNavigate();

    /*useEffect(() => {
        dispatch(toLogin());
    }, [dispatch]);

    useEffect(() => {
        dispatch(toLogin());
        if (hasExpired || !isLog) {
            navigate("/login");
        } else if (isLog && isFirstLogin) {
            navigate("/");
            dispatch(setFirstLogin(false));
        }
    }, [dispatch, navigate, hasExpired, isLog, isFirstLogin]);*/

    return (
        <>
            <ReactNotifications />
            <BreadCrumbsContext.Provider value={{ value, setValue }}>
                <CustomAppBar bcs={value} toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen} closeDrawer={closeDrawer} />
                <div className={"App " + (isDrawerOpen ? "toggled" : "")}>
                    <Routes>
                        <Route path="/createproduct" element={isLog ? <ProductCreatePage /> : <Navigate replace to={"/error"} />} />
                        <Route path="/product/:refciale/*" element={isLog ? <Product /> : <Navigate replace to={"/error"} />} />
                        <Route path="/catalog" element={isLog ? <Catalog /> : <Navigate replace to={"/error"} />} />
                        <Route path="/catalog/:ID" element={isLog ? <Catalog /> : <Navigate replace to={"/error"} />} />
                        <Route path="/stats" element={isLog ? <Stats /> : <Navigate replace to={"/error"} />} />
                        <Route path="/cartouche" element={isLog ? <Cartouche /> : <Navigate replace to={"/error"} />} />
                        <Route path="/" element={isLog ? <Home /> : <Navigate replace to={"/error"} />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/error" element={<Error />} />
                        <Route path="/dev" element={isLog ? <Dev /> : <Navigate replace to={"/error"} />} />
                        <Route path="/account" element={isLog ? <Account /> : <Navigate replace to={"/error"} />} />
                        {/* <Route path="/createproduct" element={<ProductCreatePage />} />
                        <Route path="/product/:refciale/*" element={<Product />} />
                        <Route path="/catalog" element={<Catalog />} />
                        <Route path="/catalog/:ID" element={<Catalog />} />
                        <Route path="/stats" element={<Stats />} />
                        <Route path="/cartouche" element={<Cartouche />} />
                        <Route path="/" element={<Home />} />
                        <Route path="/error" element={<Error />} />
                        <Route path="/dev" element={<Dev />} />
                        <Route path="/account" element={<Account />} />   */}
                    </Routes>
                </div>
            </BreadCrumbsContext.Provider>
        </>
    );
};

export default App;
