import AccountComponent from "src/components/Account";

const Account = () => {
    return (
        <>
            <AccountComponent />
        </>
    );
};

export default Account;
