import { createTheme } from "@mui/material";

const theme = createTheme({
    typography: {
        fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto", '"Helvetica Neue"', "Arial", "sans-serif"].join(","),
    },
    palette: {
        primary: {
            main: "#4C49AC",
        },
        secondary: {
            main: "#6493e6",
        },
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: "h2",
                    h2: "h2",
                    h3: "h2",
                    h4: "h2",
                    h5: "h2",
                    h6: "h2",
                    subtitle1: "h2",
                    subtitle2: "h2",
                    body1: "p",
                    body2: "p",
                },
            },
        },
    },
});

export default theme;
