import { Backdrop, Button, Fade, Grid, Modal, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { addCatalogTree, getBreadcrumbs, getDataStructure, setCatalogTree } from "src/services/api";
import { DataStructureType } from "src/types/datastructure";
import { getInputValueByType } from "src/providers/inputProvider";
import useNotifications from "src/pages/Notifications/useNotifications";

type CategoryUpdateModalProps = {
    open: boolean;
    handleClose: () => void;
    categoryParentId: string;
    reload: () => void;
};

const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 3,
    overflow: "scroll",
    maxHeight: "50vh",
};

const CategoryUpdateModal = (props: CategoryUpdateModalProps) => {
    let { open, handleClose, categoryParentId, reload } = props;
    const { addNotification } = useNotifications();

    const [newCategoryName, setNewCategoryName] = useState<string>("");
    const [categoryInfos, setCategoryInfos] = useState<any>();

    useEffect(() => {
        if (categoryParentId) {
            getBreadcrumbs(categoryParentId)
                .then((data: any) => {
                    setCategoryInfos(data.data[data.data.length - 1]);
                })
                .catch((error) => {
                    setCategoryInfos(null);
                });
        }
    }, []);

    useEffect(() => {
        if (!categoryInfos) return;
        setNewCategoryName(categoryInfos.category_name);
    }, [categoryInfos]);

    const updateCategory = () => {
        if (!newCategoryName) return;
        setCatalogTree(categoryInfos.category_parent, newCategoryName, categoryParentId)
            .then((data: any) => {
                reload();
                addNotification("success", "Catégorie mise à jour", "La catégorie a bien été mise à jour !");
            })
            .catch(() => {
                addNotification("danger", "Une erreur est survenue", "La catégorie n'a pas pu être mise à jour...");
            });
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={modalStyle}>
                    {categoryInfos && (
                        <>
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                Modification de la catégorie
                            </Typography>
                            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                Pour la modification d'une catégorie, veuillez remplir son nouveau nom.
                            </Typography>

                            <Box sx={{ mt: 1 }}>
                                <TextField
                                    id="outlined-basic"
                                    label="Nom de la catégorie"
                                    variant="standard"
                                    required
                                    value={newCategoryName}
                                    onChange={(e: any) => setNewCategoryName(e.target.value)}
                                    sx={{ width: "100%" }}
                                />
                            </Box>
                            <Box sx={{ display: "flex", width: "100%", mt: 3, alignItems: "center", justifyContent: "center" }}>
                                <Button onClick={updateCategory}>Modifier la catégorie</Button>
                            </Box>
                        </>
                    )}
                    {categoryInfos === undefined && <p>TODO loader</p>}
                    {categoryInfos === null && <p>Une erreur est survenue, veuillez rééssayer plus tard...</p>}
                </Box>
            </Fade>
        </Modal>
    );
};

export default CategoryUpdateModal;
