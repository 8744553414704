import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import useNotifications from "../Notifications/useNotifications";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toLogin, setFirstLogin } from "src/features/auth/auth";
import { loginFailure, loginSuccess } from "src/features/auth/actions";
import jwt_decode from "jwt-decode";
import axios from "axios";

const Login = () => {
    const dispatch = useDispatch();
    const isLog = useSelector((state: any) => state.auth.isLog);
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("t") ?? "";

        if (token) {
            console.log('token', token);
            const fetchUserData = async () => {
                try {
                    const decoded = jwt_decode(token) as any;
                    const data = { token };
                    const axiosConfig = {
                        headers: {
                            "X-API-KEY": "U2l0eUdlc3QtdXJtZXRTZXJ2aWNlcw",
                        },
                    };

                    const response = await axios.post("https://portal.intraneturmet.fr/API/getuserinfo.html", data, axiosConfig);
                    if (response.data.status === "USER_FOUND") {
                        dispatch(toLogin({
                            uuid: decoded.uuid,
                            iat: decoded.iat,
                            exp: decoded.exp,
                            userEmail: response.data.userEmail,
                            userAddressName: response.data.userAddressName,
                            userPhone: response.data.userPhone,
                            isLog: true,
                        }));
                    } else {
                        dispatch(toLogin({ isLog: false, }));
                    }
                } catch (error: any) {
                    dispatch(toLogin({ isLog: false, }));
                }
            };

            fetchUserData();
        }
    }, [dispatch]);

    useEffect(() => {
        if (isLog) {
            navigate("/");
            dispatch(setFirstLogin(false));
        } else {
            navigate("/error");
        }
    }, [isLog]);

    return <Grid sx={{ width: "100%", mt: 15 }}>{/* Contenu de la page d'erreur */}</Grid>;
};

export default Login;
