import { createSlice } from "@reduxjs/toolkit";

type Breadcrumb = {
    ID: string;
    category_name: string;
};

type BreadcrumbState = {
    breadcrumb: Breadcrumb[];
};

const initialState: BreadcrumbState = {
    breadcrumb: [],
};

const breadcrumbSlice = createSlice({
    name: "breadcrumb",
    initialState,
    reducers: {
        setBreadcrumb: (state, action) => {
            state.breadcrumb = action.payload;
        },
    },
});

export const { setBreadcrumb } = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;
