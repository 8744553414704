import { Autocomplete, Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography } from "@mui/material";
import Switch from "@mui/material/Switch";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Dropzone from "src/components/Dropzone";
import { Field } from "../types/datastructure";
import MultiSelectInput from "src/components/Product/MultiSelectInput";

export const getInputByType = (
    _: Field,
    index: number,
    indexParent: number,
    dataTypes: any,
    state: any,
    handleState: (index: number, name: string, value: any, indexDS: number) => void,
    indexInput: number,
    fromAccordion: boolean = false,
    mode: string = "edit",
    noColor: boolean = false
) => {
    let inputContent;

    const inputFormatControl = new Map<string, string>([
        ["integer", "number"],
        ["float", "number"],
        ["email", "email"],
        ["DD/MM/YYYY", "date"],
    ]);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const isEmail = (email: string) => emailPattern.test(email);

    switch (_.input_type) {
        case "checkbox":
            if ((_.format_control as string) in dataTypes) {
                let data = dataTypes[_.format_control as string].join(", ");
                inputContent = (
                    <FormControlLabel
                        control={<Switch checked={state} onChange={(e: any) => handleState(indexParent, _.field_name, e.target.checked, indexInput)} required={_.mandatory} />}
                        label={_.field_desc}
                    />
                );
            } else {
                inputContent = (
                    <FormControlLabel
                        control={<Switch checked={state} onChange={(e: any) => handleState(indexParent, _.field_name, e.target.checked, indexInput)} required={_.mandatory} />}
                        label={_.field_desc}
                    />
                );
            }
            break;

        case "text":
            if (_.field_type == "email") {
                inputContent = (
                    <TextField
                        required={_.mandatory}
                        label={_.field_desc}
                        value={state ?? ""}
                        type={"email"}
                        error={!isEmail(state ?? "")}
                        onChange={(e: any) => handleState(indexParent, _.field_name, e.target.value, indexInput)}
                        variant="filled"
                        disabled={mode === "edit" ? !_.is_editable : false}
                        className={`${_.format_control == "DD/MM/YYYY" ? "date-input" : ""}`}
                        InputProps={{
                            type: inputFormatControl.get(_.format_control as string),
                        }}
                        sx={{ backgroundColor: "#f4f4f4" }}
                    />
                );
            } else if (_.field_type == "array") {
                state = Array.isArray(state) ? state : [state];

                inputContent = (
                    <MultiSelectInput state={state} handleState={handleState} indexParent={indexParent} field={_} indexInput={indexInput} placeholder={_.field_desc} />
                );
            } else {
                state = state != "" && _.format_control === "DD/MM/YYYY" ? state?.split("/") : state;
                if (Array.isArray(state) && state.length > 1 && _.format_control === "DD/MM/YYYY") {
                    state = `${state[state.length - 1]}-${state[1]}-${state[0]}`;
                }
                inputContent = (
                    <TextField
                        required={_.mandatory}
                        label={_.field_desc}
                        value={state ?? ""}
                        onChange={(evt) => {
                            // if (evt.code === "KeyM" || evt.code === "NumpadDecimal") {
                            //     handleState
                            // }
                            // if ((evt.nativeEvent as InputEvent).data === ",") {
                            //     // pass
                            // } else {
                            handleState(indexParent, _.field_name, evt.target.value, indexInput);
                        }}
                        variant="filled"
                        error={_.mandatory && !state}
                        disabled={mode === "edit" ? !_.is_editable : false}
                        className={`${_.format_control === "DD/MM/YYYY" ? "date-input" : ""}`}
                        onKeyDown={(evt) => {
                            if (_.format_control === "DD/MM/YYYY") {
                                let year = Array.isArray(state) ? state[0].split("-")[0] : state.split("-")[0];

                                if (year.length == 4 && year[0] != "0" && evt.keyCode != 8) {
                                    evt.preventDefault();
                                }
                            } else {
                                if (inputFormatControl.get(_.format_control as string) === "number" && ["e", "E", "+", "-", ","].includes(evt.key)) {
                                    evt.preventDefault();
                                }
                            }
                        }}
                        InputProps={{
                            type: inputFormatControl.get(_.format_control as string),
                        }}
                        inputProps={{
                            step: _.format_control === "float" ? "0.1" : "1",
                        }}
                        sx={{ backgroundColor: "#f4f4f4" }}
                    />
                );
            }
            break;

        case "select":
            if (_.field_type === "boolean") {
                if ((_.format_control as string) in dataTypes) {
                    let data = dataTypes[_.format_control as string].join(", ");
                    inputContent = (
                        <FormControlLabel
                            control={
                                <Switch checked={state} onChange={(e: any) => handleState(indexParent, _.field_name, e.target.checked, indexInput)} required={_.mandatory} />
                            }
                            label={_.field_desc}
                        />
                    );
                    break;
                } else {
                    inputContent = (
                        <FormControlLabel
                            control={
                                <Switch checked={state} onChange={(e: any) => handleState(indexParent, _.field_name, e.target.checked, indexInput)} required={_.mandatory} />
                            }
                            label={_.field_desc}
                        />
                    );
                }
                break;
            }

            if ((_.format_control as string) in dataTypes) {
                let data = dataTypes[_.format_control as string];
                const formattedOptions = data.map((x: any) => (x = `${x.desc} - ${x.unit ?? x.type}`)) ?? [];
                const multiple = _.field_type === "array";
                if (multiple) {
                    state = Array.isArray(state) && state?.length ? state : [];
                } else {
                    state = state !== "" ? state : null;
                }

                inputContent = (
                    <Autocomplete
                        disablePortal
                        options={formattedOptions}
                        multiple={multiple}
                        sx={{ width: 300, paddingBottom: 4 }}
                        defaultValue={state}
                        className={`${multiple ? "MuiAutocomplete-multiple" : ""}`}
                        onChange={(e: any, value: any) => handleState(indexParent, _.field_name, value, indexInput)}
                        renderInput={(params) => <TextField sx={{ backgroundColor: "#f4f4f4" }} variant="filled" {...params} label={_.field_desc} />}
                    />
                );
            } else {
                const options = Array.isArray(_.format_control) ? _.format_control : [];
                inputContent = (
                    <Autocomplete
                        disablePortal
                        options={options}
                        defaultValue={state}
                        sx={{ width: 300, paddingBottom: 4 }}
                        onChange={(e: any, value: any) => handleState(indexParent, _.field_name, value, indexInput)}
                        renderInput={(params) => <TextField sx={{ backgroundColor: "#f4f4f4" }} variant="filled" {...params} label={_.field_desc} />}
                    />
                );
            }
            break;

        case "autocomplete":
            inputContent = (
                <TextField
                    sx={{ backgroundColor: "#f4f4f4" }}
                    required={_.mandatory}
                    label=""
                    value={state ?? ""}
                    onChange={(e: any) => handleState(index, _.field_name, e.target.value, indexInput)}
                />
            );

            break;

        case "wysiwyg":
            inputContent = <ReactQuill theme="snow" value={state ?? ""} onChange={(e: any) => handleState(indexParent, _.field_name, e, indexInput)} />;
            break;

        case "dropzone":
            inputContent = <Dropzone name={"file"} description={_.field_desc} state={state} handleState={handleState} indexParent={indexParent} indexField={indexInput} />;
            break;

        case "string":
            inputContent = (
                <TextField
                    sx={{ backgroundColor: "#f4f4f4" }}
                    required={_.mandatory}
                    label=""
                    value={state ?? ""}
                    onChange={(e: any) => handleState(index, _.field_name, e.target.value, indexInput)}
                />
            );
            break;

        default:
            inputContent = <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />;
            break;
    }

    return (
        <Grid item xs={_.input_type == "wysiwyg" ? 12 : 6} key={`item-form-${index}`} sx={{ paddingX: "20px", paddingY: "10px" }}>
            <FormGroup className={`${noColor ? "force-colorless" : ""}`}>
                <Typography
                    variant="h5"
                    color={_.mandatory && !state && state !== false && state !== undefined ? "red" : "text.secondary"}
                    align="center"
                    sx={{ mb: 2, mt: 2, fontWeight: "Bold", fontSize: "12px", textAlign: "left" }}
                >
                    {_.field_desc} {_.mandatory ? "*" : ""}
                </Typography>
                {inputContent}
            </FormGroup>
        </Grid>
    );
};

export const getInputValueByType = (_: Field) => {
    switch (_.input_type) {
        case "checkbox":
            return false;
        case "text":
            return "";
        case "select":
            if (_.field_type === "boolean") {
                return false;
            }
            return "";
        case "wysiwyg":
            return "";
        case "dropzone":
            return "dropzone";
        case "string":
            return "";

        default:
            return "An error occurred...";
    }
};
