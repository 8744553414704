import { Backdrop, Button, Fade, Grid, Modal, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { addCatalogTree, getDataStructure } from "src/services/api";
import { DataStructureType } from "src/types/datastructure";
import { getInputValueByType } from "src/providers/inputProvider";
import useNotifications from "src/pages/Notifications/useNotifications";

type CategoryCreateModalProps = {
    open: boolean;
    handleClose: () => void;
    categoryParentId: string;
    refreshData: () => void;
};

const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 3,
    overflow: "scroll",
    maxHeight: "50vh",
};

const CategoryCreateModal = (props: CategoryCreateModalProps) => {
    let { open, handleClose, categoryParentId, refreshData } = props;
    const { addNotification } = useNotifications();

    const [categoryName, setCategoryName] = useState<string>("");

    const createCategory = () => {
        if (!categoryName) return;
        addCatalogTree(categoryParentId, categoryName)
            .then((data: any) => {
                // Reset input to empty
                setCategoryName("");
                // Force close popup
                handleClose();
                // Refresh data in Catalog component
                refreshData();
                addNotification("success", "Catégorie créée", "La catégorie a bien été créée !");
            })
            .catch((e: any) => {
                addNotification("danger", "Une erreur est survenue", "La catégorie n'a pas pu être créée...");
            });
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={modalStyle}>
                    <Typography id="transition-modal-title" variant="h6" component="h2">
                        Création de catégorie
                    </Typography>
                    <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                        Pour la création d'une catégorie, veuillez remplir son nom.
                    </Typography>

                    <Box sx={{ mt: 1 }}>
                        <TextField
                            id="outlined-basic"
                            label="Nom de la catégorie"
                            variant="standard"
                            required
                            value={categoryName}
                            onChange={(e: any) => setCategoryName(e.target.value)}
                            sx={{ width: "100%" }}
                        />
                    </Box>
                    <Box sx={{ display: "flex", width: "100%", mt: 3, alignItems: "center", justifyContent: "center" }}>
                        <Button variant="contained" sx={{ mr: 1 }} onClick={createCategory}>
                            Créer la catégorie
                        </Button>
                        <Button variant="contained" onClick={handleClose} color="error">
                            Annuler
                        </Button>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    );
};

export default CategoryCreateModal;
