import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import useNotifications from "../Notifications/useNotifications";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Error = () => {
    const { addNotification } = useNotifications();
    const isLog = useSelector((state: any) => state.auth.isLog);
    const navigate = useNavigate();

    useEffect(() => {
        if (isLog != true) {
            setTimeout(() => {
                addNotification("danger", "Erreur d'authentification", "Vous ne possédez pas les droits pour accéder à l'application");
            }, 200);
        }
        if (isLog) {
            navigate('/');
        }
    }, [isLog]);

    return <Grid sx={{ width: "100%", mt: 15 }}>{/* Contenu de la page d'erreur */}</Grid>;
};

export default Error;
