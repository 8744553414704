import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { LOGIN_FAILURE, LOGIN_SUCCESS } from "./actions";

export const auth = createSlice({
    name: "counter",
    initialState: {
        uuid: "",
        iat: "",
        exp: 0,
        isLog: false,
        userEmail: "",
        userAddressName: "",
        userPhone: "",
        hasExpired: true,
        isFirstLogin: false,
    },
    reducers: {
        toLogin: (state, action) => {
            return {
                ...action.payload,
            };
        },
        setFirstLogin: (state, action) => {
            state.isFirstLogin = action.payload;
        },
    },
});
export const isLog = (state: any) => state.counter.isLog;
export const selectUuid = (state: any) => state.uuid;
export const selectIat = (state: any) => state.iat;
export const selectExp = (state: any) => state.exp;
export const selectUserEmail = (state: any) => state.userEmail;
export const selectUserAddressName = (state: any) => state.userAddressName;
export const selectUserPhone = (state: any) => state.userPhone;
export const { toLogin, setFirstLogin } = auth.actions;

export default auth.reducer;
