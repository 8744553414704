import { useEffect, useState } from "react";
import { Typography, Toolbar, Box, AppBar, Grid, ClickAwayListener } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import "./index.scss";
import logo from "../../assets/imgs/PIM-logo.png";
import { useNavigate } from "react-router-dom";
import SideBar from "./SideBar/index";
import SearchBar from "./SearchBar";
import { Breadcrumb } from "../../context/breadcumbsContext";

import { styled, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
// import Arborescence, { Category } from "../SideBar/arborescence";

export type CustomAppBarType = {
    content: any;
};
const drawerWidth = 240;

const CustomAppBar = (props: { bcs: Breadcrumb[]; toggleDrawer: any; isDrawerOpen: boolean; closeDrawer: any }) => {
    const navigate = useNavigate();

    const { bcs, toggleDrawer, isDrawerOpen, closeDrawer } = props;

    const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
        open?: boolean;
    }>(({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }));

    interface AppBarProps extends MuiAppBarProps {
        open?: boolean;
    }

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== "open",
    })<AppBarProps>(({ theme, open }) => ({
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            width: `100%`,
            marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    return (
        <ClickAwayListener
            onClickAway={() => {
                closeDrawer();
            }}
        >
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar position="fixed" open={isDrawerOpen} sx={{ backgroundColor: "#fff" }}>
                    <Toolbar sx={{ display: "flex", justifyContent: "space-between", widows: "100%" }}>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <Grid
                                onClick={() => {
                                    navigate("/");
                                }}
                                sx={{ cursor: "pointer", mr: 2 }}
                            >
                                <img src={logo} />
                            </Grid>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleDrawer}
                                edge="start"
                                sx={{ mr: 2, ...(isDrawerOpen && { display: "none" }), color: "#4C49AC" }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={closeDrawer}
                                edge="start"
                                sx={{ mr: 2, ...(!isDrawerOpen && { display: "none" }), color: "#4C49AC" }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <SearchBar />
                        <Box>
                            <Grid
                                onClick={() => {
                                    navigate("/account");
                                }}
                                sx={{ cursor: "pointer", mr: 2, display: "flex", justifyContent: "center", alignItems: "center" }}
                            >
                                <PersonIcon sx={{ color: "#4C49AC", width: "30px", height: "30px" }} />
                            </Grid>
                        </Box>
                    </Toolbar>
                </AppBar>
                <SideBar isDrawerOpen={isDrawerOpen} closeDrawer={closeDrawer} bcs={props.bcs} />
                <Main open={isDrawerOpen}></Main>
            </Box>
        </ClickAwayListener>
    );
};

export default CustomAppBar;
