import { useState } from "react";
import { Typography, Grid } from "@mui/material";
import { Autocomplete, FormGroup, TextField } from "@mui/material";
import { getInputByType } from "src/providers/inputProvider";
import { searchAt } from "src/services/api";
import { createFilterOptions } from "@mui/material/Autocomplete";

const MultiInputComponent = (props: any) => {
    const [options, setOptions] = useState<string[]>([]);
    const [searchData, setSearchData] = useState([]);
    const [selectedData, setSelectedData] = useState<string[] | undefined>([]);
    const [customOptions, setCustomOptions] = useState<string[]>([]);
    const filter = createFilterOptions<string>();

    const search = (value: any, linkToDesc: any, linkTo: any, field: any) => {
        const linkToDescValues = linkToDesc ? linkToDesc.split(".") : [""];
        const lastLinkDescToValue = linkToDescValues[linkToDescValues.length - 1];
        const linkToValues = linkTo.split(".");
        const lastLinkToValue = linkToValues[linkToValues.length - 1];
        const resultData: string[] = [];
        if (!field.api_control && field.is_editable) {
            setOptions([]);
        } else {
            searchAt(field.format_control, value)
                .then((responseData: any) => {
                    if (responseData.data.totalResult) {
                        responseData.data.results.forEach((result: any) => {
                            resultData.push(result.REFCIALE);
                        });
                        setOptions(resultData);
                    } else {
                        responseData.data.description.jsonData.map((field: any, indexField: any) => {
                            resultData.push(field[lastLinkToValue] + " - " + field[lastLinkDescToValue]);
                        });

                        setOptions(resultData);
                        setSearchData(responseData.data.description.jsonData);
                    }
                })
                .catch((error: any) => {
                    console.error(error);
                    setOptions([]);
                    setSearchData([]);
                });
        }
    };

    const handleAutocompleteChange = (indexField: any, value: any, field: any) => {
        if (typeof value === "string") {
            setOptions([]);
            const valueParse = value.split(" ");
            const tempSelectedData = searchData.find((data: any) => {
                for (const key in data) {
                    if (data[key] === valueParse[0]) {
                        return true;
                    }
                }
                return false;
            });

            setSelectedData(tempSelectedData);

            let filledInput = props.fields.find((f: any) => f.field_name === field.field_name);
            filledInput && props.handleState(props.indexDS, filledInput.field_name, valueParse[0], props.index);
            props.fields.forEach((currentField: any) => {
                var parseCurrentField = currentField.field_name.split("_");
                if (currentField.field_name !== field.field_name && currentField.link_to && parseCurrentField[0] === field.field_name) {
                    let linkTo = currentField.link_to.split(".");
                    let link = linkTo[linkTo.length - 1];
                    props.handleState(props.indexDS, currentField.field_name, tempSelectedData![link], props.index);
                }
            });
        } else if (value && value.inputValue) {
            setCustomOptions([...customOptions, value.inputValue]);
            setOptions([]);
        }
    };

    return (
        <Grid container sx={{ display: "flex", p: 0, width: "100%" }}>
            {props.fields.map((field: any, indexField: any) => {
                if (field.field_type == "autocomplete") {
                    return (
                        <Grid item xs={field.input_type == "wysiwyg" ? 12 : 6} key={`item-form-${indexField}`} sx={{ paddingX: "20px", paddingY: "10px" }}>
                            <FormGroup>
                                <Typography
                                    variant="h5"
                                    color={
                                        field.mandatory &&
                                        !props.state[field.field_name] &&
                                        props.state[field.field_name] !== false &&
                                        props.state[field.field_name] !== undefined
                                            ? "red"
                                            : "text.secondary"
                                    }
                                    align="center"
                                    sx={{ mb: 2, mt: 2, fontWeight: "Bold", fontSize: "13px", textAlign: "left" }}
                                >
                                    {field.field_desc} {field.mandatory ? "*" : ""}
                                </Typography>
                                <Autocomplete
                                    sx={{ fontSize: "12px" }}
                                    key={indexField}
                                    options={[...customOptions, ...options]}
                                    value={props.state[field.field_name]}
                                    disabled={!field.is_editable}
                                    getOptionLabel={(option: any) => {
                                        return option || "";
                                    }}
                                    onChange={(event: any, value: any) => handleAutocompleteChange(indexField, value, field)}
                                    filterOptions={(options, params) => {
                                        if (field.field_name.includes("FVALUE")) {
                                            const filtered = filter(options, params);
                                            const { inputValue } = params;
                                            const isExisting = options.some((option) => inputValue === option);
                                            if (inputValue !== "" && !isExisting) {
                                                filtered.push(inputValue);
                                            }

                                            return filtered;
                                        } else {
                                            const filtered = filter(options, params);
                                            return filtered;
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={field.field_name}
                                            variant="filled"
                                            error={field.mandatory && !props.state[field.field_name]}
                                            sx={{ backgroundColor: "#f4f4f4", fontSize: "12px" }}
                                            onChange={(e: any) => {
                                                const searchValue = e.target.value;
                                                field.link_to && search(searchValue, field.link_to_desc, field.link_to, field);
                                            }}
                                            disabled={!field.is_editable && !field.api_control}
                                        />
                                    )}
                                />
                            </FormGroup>
                        </Grid>
                    );
                }
            })}

            {props.fields.map((field: any, indexField: any) => {
                if (field.field_type != "autocomplete") {
                    return getInputByType(field, indexField, props.indexDS, props.dataTypes, props.state[field.field_name], props.handleState, props.index, false, "edit");
                }
            })}
        </Grid>
    );
};

export default MultiInputComponent;
