import "./index.scss";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { NavLink } from "react-router-dom";
import { Grid } from "@mui/material";

import Card from "@mui/material/Card";

import { CardActionArea } from "@mui/material";
const Home = () => {
    return (
        <div className="Home pim-component">
            <h1>PIM Manager</h1>
            <Grid container spacing={4} className="center">
                <Grid item xs={3}>
                    {/* <Card sx={{ bgcolor: "#4C49AC", maxWidth: 345 }}>
                        <CardActionArea href="/cartouche">
                            <CardContent>Cartouche CRUD</CardContent>
                        </CardActionArea>
                    </Card> */}
                </Grid>
                <Grid item xs={3}>
                    <Card sx={{ bgcolor: "#4C49AC", maxWidth: 345 }}>
                        <CardActionArea href="/catalog">
                            <CardContent>Voir le catalogue</CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    {/* <Card sx={{ bgcolor: "#4C49AC", maxWidth: 345 }}>
                        <CardActionArea href="/stats">
                            <CardContent>Stats</CardContent>
                        </CardActionArea>
                    </Card> */}
                </Grid>
            </Grid>
        </div>
    );
};

export default Home;
