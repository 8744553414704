import React, { createContext } from "react";

export type Breadcrumb = {
    category_name: string;
    ID: string;
    niv: number;
    childrenCategories?: any[];
    childrenProducts?: any[];
};
const defaultHomeBreadcrumb: Breadcrumb = {
    category_name: "Accueil",
    ID: "",
    niv: -1,
};

const BreadCrumbsContext = createContext({
    value: [] as Breadcrumb[],
    setValue: (value: Breadcrumb[]) => {},
});

export { BreadCrumbsContext };
