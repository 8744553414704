import axiosClient from "./client";
export const getListMarque = async () => {
    return await axiosClient.get(`/getListMarque`);
};
// POST
export const getListGamme = async (input?: any) => {
    return await axiosClient.post(`/getListGamme`, {
        MARQUE: input ?? "URMET",
        COLLECTION: ["01_COMMERCE", "02_LOGISTIQUE", "03_MEDIA", "04_REGLEMENTAIRE", "06_ETIM"],
    });
};
export const getStats = async (gameChoice?: any) => {
    return await axiosClient.post(`/getStatsGammeMultiCol`, {
        GAMME: gameChoice ?? "CVIP",
        COLLECTION: ["01_COMMERCE", "02_LOGISTIQUE", "03_MEDIA", "04_REGLEMENTAIRE", "06_ETIM"],
    });
};
export const getStatsRefMultiCol = async (REF?: any) => {
    return await axiosClient.post(`/GetStatsRefMultiCol`, {
        REFCIALE: REF,
        // REFCIALE: REF ?? "E2BPP",
        COLLECTION: ["01_COMMERCE", "02_LOGISTIQUE", "03_MEDIA", "04_REGLEMENTAIRE", "06_ETIM"],
    });
};
