import { Breadcrumbs, Link } from "@mui/material";
import { useEffect, useContext } from "react";
import { getBreadcrumbs } from "src/services/api";
import { BreadCrumbsContext } from "../context/breadcumbsContext";
import { useSelector, useDispatch } from "react-redux";
import { setBreadcrumb } from "../features/breadcrumb/breadcrumb";
import { useLocation } from "react-router-dom";

type CatalogBreadcrumbsProps = {
    ID: undefined | string;
    setCategoryName: (categ: string) => void;
};

export type Breadcrumb = {
    ID: string;
    category_name: string;
    niv: number;
};

const defaultHomeBreadcrumb: Breadcrumb = {
    category_name: "Accueil",
    ID: "",
    niv: -1,
};

const linkHover = {
    "&:hover": {
        opacity: 0.8,
        transition: "200ms",
    },
    fontSize: 20,
};

const CatalogBreadcrumbs = ({ ID, setCategoryName }: CatalogBreadcrumbsProps) => {
    const { value, setValue } = useContext(BreadCrumbsContext);
    const breadcrumb = useSelector((state: any) => state.breadcrumb);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        if (ID) {
            getBreadcrumbs(ID)
                .then((data: any) => {
                    setValue([...data.data]);
                    dispatch(setBreadcrumb([...data.data])); // Dispatch l'action setBreadcrumb avec les données récupérées
                })
                .catch((error) => {});
        }
    }, []);

    useEffect(() => {
        setCategoryName(value[value.length - 1].category_name);
    }, [value]);

    return (
        <BreadCrumbsContext.Consumer>
            {({ value, setValue }) => {
                return (
                    <Breadcrumbs maxItems={5} aria-label="breadcrumb" separator="›" className="catalog-breadcrumbs">
                        {value.length > 0 &&
                            value.map((data: Breadcrumb) => {
                                return (
                                    <Link sx={linkHover} key={`bcp-${data.ID}`} href={"/catalog/" + data.ID} underline="none">
                                        {data.category_name}
                                    </Link>
                                );
                            })}
                    </Breadcrumbs>
                );
            }}
        </BreadCrumbsContext.Consumer>
    );
};

export default CatalogBreadcrumbs;
