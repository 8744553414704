import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
    tree: ["coucou ?"],
};

const treeSlice = createSlice({
    name: "tree",
    initialState,
    reducers: {
        setTree: (state, action) => {
            state.tree = action.payload;
        },
    },
});

export const { setTree } = treeSlice.actions;

export const setBreadcrumbsTree = (categories: any) => {
    return {
        type: "tree/setTree",
        payload: categories,
    };
};

export const selectTree = (state: any) => state.tree;
export default treeSlice.reducer;
