import "./index.scss";
import { useEffect, useState } from "react";
import { Box, ClickAwayListener, IconButton, Paper, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SearchResults } from "src/types/search";
import useSearch from "./useSearch";

import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import CloseIcon from "@mui/icons-material/Close";

type ResultType = {
    ID: string;
    REFCIALE?: string;
    category_name?: string;
};

const SearchBar = () => {
    const { results, searchValue, setSearchValue, isFetching, isVisible, setIsVisible, navigateTo } = useSearch();

    return (
        <ClickAwayListener onClickAway={() => setIsVisible(false)}>
            <Box className="searchBox">
                {/* <TextField
                    label="Rechercher un article dans le PIM"
                    variant="standard"
                    value={searchValue}
                    sx={{ minWidth: "500px" }}
                    onChange={(e: any) => results && setSearchValue(e.target.value)}
                    onFocus={() => setIsVisible(true)}
                /> */}
                <Paper component="form" sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 600 }}>
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        onChange={(e: any) => results && setSearchValue(e.target.value)}
                        value={searchValue}
                        onFocus={() => setIsVisible(true)}
                        placeholder="Rechercher une référence ou un produit"
                        inputProps={{ "aria-label": "Rechercher une référence ou un produit" }}
                    />
                    {searchValue && (
                        <>
                            <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={() => setSearchValue("")}>
                                <CloseIcon />
                            </IconButton>
                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        </>
                    )}

                    <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                </Paper>
                <div className="results">
                    {isVisible &&
                        results &&
                        results[0] &&
                        !isFetching &&
                        results[0].map((result: any, indexSearch: number) => {
                            const name = result.category_name ?? result.REFCIALE;
                            return (
                                <Box
                                    className="result"
                                    key={`searchresult-${indexSearch}`}
                                    onClick={(e: any) => {
                                        navigateTo(result);
                                    }}
                                >
                                    <p>
                                        {result.REFCIALE ? "Produit : " : "Catégorie : "}
                                        {name}
                                    </p>
                                </Box>
                            );
                        })}
                    {isFetching && (
                        <Box className="result">
                            <p>Chargement...</p>
                        </Box>
                    )}
                </div>
            </Box>
        </ClickAwayListener>
    );
};

export default SearchBar;
