import { Store } from "react-notifications-component";

type NotificationType = "success" | "danger" | "info" | "default" | "warning";

const useNotifications = () => {
    const addNotification = (type: NotificationType, title: string, message: string | React.ReactElement, duration: number = 5000) => {
        Store.addNotification({
            title,
            message,
            type,
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration,
                onScreen: true,
                pauseOnHover: true,
            },
        });
    };

    return {
        addNotification,
    };
};

export default useNotifications;
